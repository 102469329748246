import React, { useContext } from 'react';
import { auth } from '../firebase-config';
import TerminalContext from '../TerminalContext';
import '../styles/Footer.css';

const Footer = () => {
  const { selectedTerminal } = useContext(TerminalContext);

  if (auth.currentUser && selectedTerminal && selectedTerminal.isActivatedTerminal) {
    return (
      <>
        <div className="footer"> 

          <div className="container mt-5">        
            <div className="footer-content">
              <div className="footer-left">
                <div>
                  <strong>Name:</strong> {selectedTerminal.kitaData.name}
                </div>
                <div>
                  <strong>Adresse:</strong> {selectedTerminal.kitaData.address}
                </div>
                <div>
                  <strong>PLZ / Ort:</strong> {selectedTerminal.kitaData.zip} / {selectedTerminal.kitaData.city}
                </div>
              </div>
              <div className="footer-right">
                <div>
                  <strong>Email:</strong> {selectedTerminal.kitaData.email}
                </div>
                <div>
                  <strong>Website:</strong> {selectedTerminal.kitaData.website}
                </div>
                <div>
                  <strong>Telefon:</strong> {selectedTerminal.kitaData.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div>Keine Terminaldaten vorhanden. Terminal ist deaktiviert. Bitte wenden Sie sich an Ihre Kitaleitung.</div>;
  }
};

export default Footer;
