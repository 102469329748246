import React, { useContext, useState, useEffect, useMemo } from 'react';
import TerminalContext from '../TerminalContext';
import SignOperation from '../shared/SignOperation';
import ShowOperations from '../shared/ShowOperations';
import { auth } from '../firebase-config';
import { Modal, Button, Spinner } from 'react-bootstrap';
import '../styles/Operations.css';
import '../styles/CheckinChild.css';

const CheckinChild = () => {
  const { attendingChildren, employeeData, overdueTasks, selectedTerminal, operationSavedTrigger, updateOperationSavedTrigger } = useContext(TerminalContext);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [operationSaved, setOperationSaved] = useState(false);

  const [type, setType] = useState('');
  const [group, setGroup] = useState('');
  const [attendance, setAttendance] = useState('');
  const [comment, setComment] = useState('');

  const [missingType, setMissingType] = useState(false);
  const [missingGroup, setMissingGroup] = useState(false);
  const [missingAttendance, setMissingAttendance] = useState(false);


  const handleChildClick = (childId) => {
    if (selectedChildren.includes(childId)) {
      setSelectedChildren([]);
    } else {
      setSelectedChildren([childId]);
    }
  };

  const handleSaveTask = () => {
    if (selectedChildren.length === 0) {
      setErrorMessage('Bitte wählen Sie ein Kind aus.');
    } else {
      const selectedChild = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0]);
      if (selectedChild && selectedChild.checkin) {
        setErrorMessage('Das Kind ist bereits eingecheckt.');
      } else {
        setShowInfoModal(true);
        setErrorMessage('');
      }
    }
  };

  const handleSignOperationClose = (wasSuccessful) => {
    if (wasSuccessful) {
      setSelectedChildren([]);
      setOperationSaved(true);
      updateOperationSavedTrigger(!operationSavedTrigger);
    }
  };

  const prepareDataForSignOperation = () => {
    return selectedChildren.map(childId => {
      const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);
      const payload = {
        firstname: child.firstname,
        lastname: child.lastname,
        attendance_type: type === 'Ganzer Tag' ? 'fullday' : type === 'Halber Tag' ? 'halfday' : '',
        changed_at: new Date().toISOString(),
        changed_by: auth.currentUser.uid,
        childRef: `/child/${child.id}`,
        comment: comment,
        created_at: new Date().toISOString(),
        created_by: auth.currentUser.uid,
        groupRef: `/kita/${selectedTerminal.kitaData.kitaId}/group/${group}`,
        isAttending: attendance === 'Anwesend',
        isSick: attendance === 'Krank',
        inHoliday: attendance === 'Ferien',
        givenDate: new Date(),
      };
      return {
        childId: child.id,
        childRef: child.childRef,
        action: "checkinChild",
        payload: payload,
        comment: comment
      };
    });
  };

  const childrenList = useMemo(() => {
    return attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || [];
  }, [attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Überprüfe für jedes Kind, ob es in einer der Kategorien von overdueTasks ist
      childrenList.forEach(child => {
        const isOverdue = Object.values(overdueTasks).some(categoryTasks =>
          categoryTasks.some(task => task.childId === child.id)
        );
        const childElement = document.querySelector(`.child-tile[data-child-id="${child.id}"]`);
        if (childElement) {
          if (isOverdue) {
            childElement.classList.add('overdue'); // Füge Klasse für Überfälligkeit hinzu
          } else {
            childElement.classList.remove('overdue'); // Entferne Klasse für Überfälligkeit
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval); // Aufräumen beim Komponenten-Unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdueTasks]);

  if (!employeeData || !attendingChildren || !attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.length === 0) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Daten werden geladen...</p>
      </div>
    );
  }

  const sortedChildrenList = [...childrenList].sort((a, b) => (a.checkin === b.checkin) ? 0 : a.checkin ? 1 : -1);

  const selectedChild = selectedChildren.length > 0
    ? attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0])
    : null;

  const handleOptionClick = (setter, value, category) => {
    setter(value);
  
    if (category === 'type') {
      setMissingType(false);
    } else if (category === 'group') {
      setMissingGroup(false);
    } else if (category === 'attendance') {
      setMissingAttendance(false);
    }
  };
    

  const handleModalClose = () => {
    setShowInfoModal(false);
    setShowSignModal(false);
    // Reset the states when the modal is closed
    setSelectedChildren([]);
    setType('');
    setGroup('');
    setAttendance('');
    setComment('');
    setMissingType(false);
    setMissingGroup(false);
    setMissingAttendance(false);
  };

  const groups = selectedTerminal.kitaData.groups || [];

  const isAlreadyCheckedIn = selectedChild && selectedChild.checkin;

  const handleContinue = () => {
    setMissingType(!type);
    setMissingGroup(!group);
    setMissingAttendance(!attendance);
    if (type && group && attendance) {
      setShowInfoModal(false);
      setShowSignModal(true);
    }
  };
  

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Check In</h2>
      <p className="text-center mb-4">Bestätige die Anwesenheit eines Kindes:</p>
      {errorMessage && <div className="alert alert-warning text-center">{errorMessage}</div>}
      <div className="child-tiles-container">
        {sortedChildrenList.map((child) => (
          <div 
            key={child.id} 
            className={`child-tile ${selectedChildren.includes(child.id) ? 'selected' : ''} ${child.checkin ? 'checked-in' : ''}`}
            onClick={() => handleChildClick(child.id)}
            data-child-id={child.id}
          >
            <div className="d-flex flex-column align-items-center p-2">
              <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
              <p className="m-0">{child.firstname} {child.lastname}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-primary" onClick={handleSaveTask} disabled={isAlreadyCheckedIn}>
          Kind einchecken
        </button>
      </div>

      <ShowOperations 
        action="checkinChild" 
        givenDate={new Date().toISOString().split('T')[0]} 
        operationSaved={operationSaved} 
        resetOperationSaved={() => setOperationSaved(false)}
      />

      <SignOperation
        show={showSignModal}
        onHide={() => handleModalClose()}
        onSuccessfulSubmit={() => handleSignOperationClose(true)}
        operationsData={prepareDataForSignOperation()}
      />

      <Modal show={showInfoModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Kind einchecken</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedChild && (
            <div className="d-flex flex-column align-items-center">
              <h5>{selectedChild.firstname} {selectedChild.lastname}</h5>
              <img src={selectedChild.profilePic} alt={`${selectedChild.firstname} ${selectedChild.lastname}`} className="rounded-circle mb-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
            </div>
          )}
          <div className={`modal-category ${missingType ? 'missing-field' : ''}`}>
            <h5>Typ:</h5>
            <div className="d-flex justify-content-center">
              <div className={`modal-option-tile ${type === 'Ganzer Tag' ? 'selected' : ''}`} onClick={() => handleOptionClick(setType, 'Ganzer Tag', 'type')}>
                Ganzer Tag
              </div>
              <div className={`modal-option-tile ${type === 'Halber Tag' ? 'selected' : ''}`} onClick={() => handleOptionClick(setType, 'Halber Tag', 'type')}>
                Halber Tag
              </div>
            </div>
          </div>
          <div className={`modal-category ${missingGroup ? 'missing-field' : ''}`}>
            <h5>Gruppe:</h5>
            <div className="d-flex justify-content-center">
              {groups.map((groupItem) => (
                <div 
                  key={groupItem.id} 
                  className={`modal-option-tile ${group === groupItem.id ? 'selected' : ''}`} 
                  onClick={() => handleOptionClick(setGroup, groupItem.id, 'group')}
                >
                  {groupItem.name}
                </div>
              ))}
            </div>
          </div>
          <div className={`modal-category ${missingAttendance ? 'missing-field' : ''}`}>
            <h5>Anwesenheit:</h5>
            <div className="d-flex justify-content-center">
              <div className={`modal-option-tile ${attendance === 'Anwesend' ? 'selected' : ''}`} onClick={() => handleOptionClick(setAttendance, 'Anwesend', 'attendance')}>
                Anwesend
              </div>
              <div className={`modal-option-tile ${attendance === 'Ferien' ? 'selected' : ''}`} onClick={() => handleOptionClick(setAttendance, 'Ferien', 'attendance')}>
                Ferien
              </div>
              <div className={`modal-option-tile ${attendance === 'Krank' ? 'selected' : ''}`} onClick={() => handleOptionClick(setAttendance, 'Krank', 'attendance')}>
                Krank
              </div>
            </div>
          </div>
          <div className="modal-category">
            <h5>Kommentar:</h5>
            <textarea value={comment} onChange={(e) => setComment(e.target.value)} rows="3" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Abbrechen
          </Button>
          <Button 
            variant="primary" 
            onClick={handleContinue}
          >
            Weiter
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckinChild;
