import React from 'react';

const TerminalContext = React.createContext({
  selectedTerminal: null,
  employeeData: null,
  attendingChildren: null, // Hinzufügen von attendingChildren zum Kontext
  kitaOperationsTasks: null,
  overdueTasks: null,
  operationSavedTrigger: null,
  latestActivity: null,
  updateDeviceRegistrationStatus: () => {},
  updateEmployeeData: () => {},
  updateAttendingChildrenData: () => {},
  updateKitaOperationsTasks: () => {},
  updateOverdueTasks: () => {},
  updateOperationSavedTrigger: () => {},
  updateLatestActivity: () => {}
  // Sie können auch eine Funktion hinzufügen, um attendingChildren zu aktualisieren, falls benötigt
});

export default TerminalContext;
