import React, { useContext, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { auth } from '../firebase-config';
import TerminalContext from '../TerminalContext';
import '../styles/SignOperation.css';

const SignOperation = ({ show, onHide, onSuccessfulSubmit, operationsData }) => {
  const { employeeData, selectedTerminal } = useContext(TerminalContext);
  const [code, setCode] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const selectEmployee = (employeeId) => {
    setSelectedEmployee(employeeData.find(employee => employee.id === employeeId));
    setShowKeyboard(true);
  };

  const handleNumberClick = (number) => {
    if (code.length < 4) {
      setCode(code + number); // Fügt die Nummer hinzu, wenn weniger als 4 Ziffern vorhanden sind
    }
    // Wenn bereits 4 Ziffern vorhanden sind, wird nichts weiter hinzugefügt
  };
  

  const handleBack = () => {
    setCode('');
    setErrorMessage('');
    setShowKeyboard(false);
    setSelectedEmployee(null);
  };

  const renderKeyboardKeys = () => {
    const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    return keys.map(key => (
      <div key={key} className={`key ${key === 0 ? 'zero' : ''}`} onClick={() => handleNumberClick(key)}>
        {key}
      </div>
    ));
  };

  const handleDelete = () => {
    setCode(code.slice(0, -1));
    setErrorMessage(''); 
  };

  const handleSubmit = async () => {
    if (selectedEmployee && code === selectedEmployee.personalPin) {
      setIsLoading(true);
      try {

        // Erweiterung von operationData um zusätzliche Informationen
        const enhancedOperationsData = operationsData.map(op => ({
          ...op,
          executed_by_Id: selectedEmployee.id,
          executed_by_UID: selectedEmployee.uid,
          executed_at: new Date().toISOString(), // ISO-Format für Datum/Uhrzeit
        }));

        console.log(enhancedOperationsData);

        // Backend-Aufruf
        const idToken = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
        // Haupt-Backend-Aufruf
        const mainResponse = await fetch(`${baseUrl}/ops-action`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            kitaId: selectedTerminal.kitaData.kitaId,
            givenDate: new Date().toISOString(),
            operationsData: enhancedOperationsData
          })
        });

        const mainResult = await mainResponse.json();
        
        if (!mainResponse.ok) {
          throw new Error(mainResult.message || 'Fehler bei der Verarbeitung der Anfrage.');
        }

        // Zusätzlicher Backend-Aufruf, wenn die action "checkinChild" ist
        const checkinChildOp = enhancedOperationsData.find(op => op.action === "checkinChild");

        if (checkinChildOp) {
          const additionalResponse = await fetch(`${baseUrl}/save-attendance/${selectedTerminal.kitaData.kitaId}/${checkinChildOp.childId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(checkinChildOp.payload)
          });

          const additionalResult = await additionalResponse.json();

          if (!additionalResponse.ok) {
            throw new Error(additionalResult.message || 'Fehler bei der Verarbeitung der Anfrage.');
          }
        }

        
        // Zusätzlicher Backend-Aufruf, wenn die action "checkinChild" ist
        const checkoutChildOp = enhancedOperationsData.find(op => op.action === "checkoutChild");

        if (checkoutChildOp) {
          const additionalResponse2 = await fetch(`${baseUrl}/save-child-checkout/${selectedTerminal.kitaData.kitaId}/${checkoutChildOp.childId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(checkoutChildOp.payload)
          });

          const additionalResult2 = await additionalResponse2.json();

          if (!additionalResponse2.ok) {
            throw new Error(additionalResult2.message || 'Fehler bei der Verarbeitung der Anfrage.');
          }
        }

        setActionSuccess(true);
        onSuccessfulSubmit();
        setCode('');
        setErrorMessage('');
        setIsLoading(false);
      } catch (error) {
        console.error('Fehler beim Backend-Aufruf:', error);
        setErrorMessage(error.message || 'Ein Fehler ist aufgetreten.');
        setCode('');
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Der PIN Code stimmt nicht überein.');
      setCode('');
    }
  };
  

  const handleModalClose = () => {
    setCode('');
    setErrorMessage('');
    setShowKeyboard(false);
    setSelectedEmployee(null);
    setActionSuccess(false); // Setze den Erfolgszustand zurück
    onHide();
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton onHide={handleModalClose}>
        <Modal.Title>
          {actionSuccess
            ? 'Aktion erfolgreich gespeichert'
            : showKeyboard
            ? `Auftrag visieren als ${selectedEmployee?.firstname} ${selectedEmployee?.lastname}`
            : 'Auftrag visieren'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
          {actionSuccess ? (
            <div className="text-center">
              <img src="/child-thumbsup3.png" alt="Erfolg" className="success-image" />
              <div className="my-3">Danke!</div>
            </div>
          ) : (
            <>
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              {!showKeyboard ? (
                <div className="employee-tiles-container">
                  {employeeData.map((employee) => (
                    <div
                      key={employee.id}
                      className={`tileSO employee-tile ${selectedEmployee?.id === employee.id ? 'selected' : ''}`}
                      onClick={() => selectEmployee(employee.id)}
                    >
                      {employee.firstname} {employee.lastname}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="code-indicator">
                    {new Array(4).fill(null).map((_, index) => (
                      <span key={index} className={`dot ${index < code.length ? 'filled' : ''}`}></span>
                    ))}
                  </div>
                  <div className="keyboard">
                    {renderKeyboardKeys()}
                    <div className="key delete" onClick={handleDelete}>×</div>
                  </div>
                </>
              )}
            </>
          )}
          </>
        )}
      </Modal.Body>
      {actionSuccess ? (
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose} className="mx-auto">Schliessen</Button>
        </Modal.Footer>
      ) : (!isLoading && showKeyboard) && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBack}>Zurück</Button>
          <Button variant="primary" onClick={handleSubmit}>Auftrag visieren</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
  
};

export default SignOperation;
