import React, { useState, useContext } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { auth } from '../firebase-config';
import TerminalContext from '../TerminalContext';
import '../styles/SignOperation.css';

const RemindMeLater = ({ overdueTasksCategory, onSuccessfulSubmit }) => {
  const { employeeData, selectedTerminal } = useContext(TerminalContext);
  const [showModal, setShowModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [code, setCode] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCmtMessage, setErrorCmtMessage] = useState('');
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timerInMin, setTimerInMin] = useState(30); // Standardwert für 30 min
  const [comment, setComment] = useState('');

  const handleTimerButtonClick = (timerValue) => {
    setTimerInMin(timerValue);
    setShowCommentModal(true);
  };

  const selectEmployee = (employeeId) => {
    setSelectedEmployee(employeeData.find(employee => employee.id === employeeId));
    setShowKeyboard(true);
  };

  const handleNumberClick = (number) => {
    if (code.length < 4) {
      setCode(code + number);
    }
  };

  const handleBack = () => {
    setCode('');
    setErrorMessage('');
    setShowKeyboard(false);
    setSelectedEmployee(null);
  };

  const renderKeyboardKeys = () => {
    const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    return keys.map(key => (
      <div key={key} className={`key ${key === 0 ? 'zero' : ''}`} onClick={() => handleNumberClick(key)}>
        {key}
      </div>
    ));
  };

  const handleDelete = () => {
    setCode(code.slice(0, -1));
    setErrorMessage(''); 
  };

  const handleSubmit = async () => {
    if (!overdueTasksCategory) {
      console.error("Fehler: overdueTasksCategory ist nicht definiert");
      return;
    }
    if (selectedEmployee && code === selectedEmployee.personalPin) {
      setIsLoading(true);
      try {
        // Erstellen der aktualisierten Aufgaben mit den erforderlichen Feldern und zusätzlichen Informationen
        const updatedTasks = overdueTasksCategory.map(task => ({
          kitaId: selectedTerminal.kitaData.kitaId,
          givenDate: new Date(task.executed_at._seconds * 1000).toISOString(), // Umwandlung des Firebase-Timestamps in ISO-Datumsformat
          taskId: task.taskId,
          actionId: task.actionId,
          timer_in_min: timerInMin,
          timer_set_by_Id: selectedEmployee.id,
          timer_set_by_UID: selectedEmployee.uid,
          timer_set_at: new Date().toISOString(), // Aktualisiertes ISO-Datumsformat für Datum/Uhrzeit
          comment,
          categoryId: task.categoryId || ""
        }));
        // console.log(updatedTasks);
  
        // Backend-Aufruf
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
        const response = await fetch(`${baseUrl}/ops-action-timer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
          },
          body: JSON.stringify({
             updatedTasks: updatedTasks
          })
        });
  
        const result = await response.json();
        if (response.ok) {
          console.log('Backend-Aufruf erfolgreich:', result);
          setActionSuccess(true);
          setCode('');
          setErrorMessage('');
          setComment('');
        } else {
          throw new Error(result.message || 'Fehler bei der Verarbeitung der Anfrage.');
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fehler beim Backend-Aufruf:', error);
        setErrorMessage(error.message || 'Ein Fehler ist aufgetreten.');
        setCode('');
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Der PIN Code stimmt nicht überein.');
      setCode('');
    }
  };
  

  const handleModalClose = () => {
    if(actionSuccess) {
      onSuccessfulSubmit();
      //updateOperationSavedTrigger(!operationSavedTrigger);
    }
    setCode('');
    setErrorMessage('');
    setErrorCmtMessage('');
    setShowKeyboard(false);
    setSelectedEmployee(null);
    setActionSuccess(false);
    setShowModal(false);
  };

  const handleSelectEmployeeClose = () => {
    setCode('');
    setErrorMessage('');
    setErrorCmtMessage('');
    setShowKeyboard(false);
    setSelectedEmployee(null);
    setActionSuccess(false);
    setShowModal(false);
    setShowCommentModal(true);
  };

  
  const handleCommentModalClose = () => {
    setShowCommentModal(false);
    setComment('');
    setErrorCmtMessage('');
  };
  
  const handleCommentSubmit = () => {
    if (comment.trim() !== '') {
      setShowCommentModal(false);
      setErrorCmtMessage('');
      setShowModal(true);
    } else {
      setErrorCmtMessage('Bitte gebe einen Grund für die Verschiebung an.');
    }
  };


  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button 
          variant="secondary" 
          onClick={() => { handleTimerButtonClick(30); }} 
          style={{ width: '45%' }}
          disabled={overdueTasksCategory.length === 0}
        >
          In 30 min erinnern
        </Button>
        <Button 
          variant="secondary" 
          onClick={() => { handleTimerButtonClick(60); }} 
          style={{ width: '45%' }}
          disabled={overdueTasksCategory.length === 0}
        >
          In 60 min erinnern
        </Button>
      </div>

      {/* Comment Modal */}
      <Modal show={showCommentModal} onHide={handleCommentModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Grund hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
        {errorCmtMessage && <div className="alert alert-danger mt-2">{errorCmtMessage}</div>}
          <div>Bitte begründe die Verschiebung der Aufgabe:</div>
          <textarea 
            className="form-control mt-2" 
            value={comment} 
            onChange={(e) => setComment(e.target.value)} 
            rows="3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCommentModalClose}>Zurück</Button>
          <Button variant="primary" onClick={handleCommentSubmit}>Weiter</Button>
        </Modal.Footer>
      </Modal>

      {/* Main Modal */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title>
            {actionSuccess
              ? 'Aktion erfolgreich gespeichert'
              : showKeyboard
              ? `Auftrag visieren als ${selectedEmployee?.firstname} ${selectedEmployee?.lastname}`
              : 'Auftrag visieren'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="text-center" style={{ paddingTop: '200px', paddingBottom: '200px' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <>
            {actionSuccess ? (
              <div className="text-center">
                <img src="/child-thumbsup3.png" alt="Erfolg" className="success-image" />
                <div className="my-3">Super, die Aufgabe wurde um {timerInMin} Minuten verschoben!</div>
              </div>
            ) : (
              <>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {!showKeyboard ? (
                  <div className="employee-tiles-container">
                    {employeeData.map((employee) => (
                        <div
                        key={employee.id}
                        className={`tileSO employee-tile ${selectedEmployee?.id === employee.id ? 'selected' : ''}`}
                        onClick={() => selectEmployee(employee.id)}
                        >
                        {employee.firstname} {employee.lastname}
                        </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="code-indicator">
                      {new Array(4).fill(null).map((_, index) => (
                        <span key={index} className={`dot ${index < code.length ? 'filled' : ''}`}></span>
                      ))}
                    </div>
                    <div className="keyboard">
                      {renderKeyboardKeys()}
                      <div className="key delete" onClick={handleDelete}>×</div>
                    </div>
                  </>
                )}
              </>
            )}
            </>
          )}
        </Modal.Body>
        {actionSuccess ? (
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose} className="mx-auto">Schliessen</Button>
          </Modal.Footer>
        ) : !showKeyboard && (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSelectEmployeeClose}>Zurück</Button>
            </Modal.Footer>
          )}
        {showKeyboard && !actionSuccess && !isLoading && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleBack}>Zurück</Button>
            <Button variant="primary" onClick={handleSubmit}>Auftrag visieren</Button>
          </Modal.Footer>
        )}

      </Modal>
    </>
  );
};

export default RemindMeLater;
