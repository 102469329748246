import { useContext } from 'react';
import TerminalContext from '../TerminalContext';

const useSharedFunctionsForActivities = () => {
  const { updateLatestActivity } = useContext(TerminalContext);

  const calculateLatestActivity = (operations) => {
    // Gruppiere alle kitaOperationsTasks nach task.childId
    const tasksByChild = operations.reduce((acc, task) => {
      if (!acc[task.childId]) {
        acc[task.childId] = [];
      }
      acc[task.childId].push(task);
      return acc;
    }, {});

    // Sortiere tasks nach executed_at in absteigender Reihenfolge
    for (const childId in tasksByChild) {
      tasksByChild[childId].sort((a, b) => b.executed_at._seconds - a.executed_at._seconds);
    }

    // Erstelle ein Array, das jeweils die childId und den neuesten task.action enthält
    const latestActivities = Object.keys(tasksByChild).map(childId => ({
      childId,
      latestAction: tasksByChild[childId][0].actionId,
      childName: tasksByChild[childId][0].childName,
    }));

    // Füge dieses neue Array in updateLatestActivity ein
    updateLatestActivity(latestActivities);
  };

  return {
    calculateLatestActivity,
  };
};

export default useSharedFunctionsForActivities;
