const ACTION_DESCRIPTIONS = {
    changeDiaper: 'Windel gewechselt',
    checkinChild: 'Einchecken',
    checkoutChild: 'Auschecken',
    feedChild: 'Mahlzeit erhalten',
    feedChildEat: 'Essen',
    feedChildDrink: 'Trinken',
    sleep: 'Schlafen',
    awakening: 'Aufwachen',
    playTime: 'Spielzeit',
    medicineGiven: 'Medizin gegeben',
    // Add more actions as needed
};

export default ACTION_DESCRIPTIONS;
