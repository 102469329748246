// shared/config/actionItems.js
const getIconPath = (action, type) => {
  if (action === 'feedChild') {
    switch (type) {
      case 'Essen':
        return '/meal.png';
      case 'Trinken':
        return '/milk-bottle.png';
      default:
        return '/meal.png';
    }
  }
  switch (action) {
    case 'changeDiaper':
      return '/diaper.png';
    case 'checkinChild':
      return '/checkin.png';
    case 'checkoutChild':
      return '/checkout.png';
    case 'feedChild':
      return '/meal.png';
    case 'sleep':
      return '/sleep.png';
    case 'awakening':
      return '/awakening.png';
    case 'bad':
      return '/bad.png';
    case 'good':
      return '/good.png';
    case 'verygood':
      return '/verygood.png';
    case 'fullbottle':
      return '/fullbottle.png';
    case 'emptybottle':
      return '/emptybottle.png';
    case 'milk-bottle':
      return '/milk-bottle.png';
    default:
      return '';
  }
};

export default getIconPath;
