import { useEffect, useContext } from 'react';
import TerminalContext from '../TerminalContext';
import { auth } from '../firebase-config';


const actionHandlers = {
  changeDiaper: (task, expirationTime) => {
    //console.log(task);
      if (expirationTime < new Date()) {
          return task;  // Only push to overdue if it meets the condition
      }
  },
  checkinChild: (task, expirationTime) => {
      // For checkinChild, we do nothing regardless of the condition
      return null;
  },
  // You can add more handlers for other actionIds as needed
  defaultHandler: (task, expirationTime) => {
      // This could be a default handler if no specific actionId handler is found
      if (expirationTime < new Date()) {
          return null;
      }
  }
};

const checkForSpecificTimeTasks = (kitaOperationsTasks, attendingChildren, overdue) => {
  if (kitaOperationsTasks) {
    const now = new Date();
    const triggerStartTime = new Date();
    const triggerEndTime = new Date();

    // Setze die Trigger-Startzeit auf 17:00 Uhr
    triggerStartTime.setHours(11, 0, 0, 0);
    // Setze die Trigger-Endzeit auf 17:10 Uhr
    triggerEndTime.setHours(14, 0, 0, 0);

    //console.log("Trigger-Startzeit gesetzt auf: ", triggerStartTime);
    //console.log("Trigger-Endzeit gesetzt auf: ", triggerEndTime);

    // prüfe ob ein Kind bis 11 Uhr noch nichts zu essen erhalten hat
    if (now >= triggerStartTime && now <= triggerEndTime) {
      attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.forEach(child => {
        // Kinder mit checkout = true oder isAttending = false überspringen
        if (child.checkout || !child.isAttending) {
          //console.log(`${child.firstname} ${child.lastname} wird übersprungen`);
          return;
        }
        const hasBeenFeed = kitaOperationsTasks.some(task => task.childId === child.id && task.actionId === 'feedChild');

        if (!hasBeenFeed) {
          const checkinTask = kitaOperationsTasks.find(task => task.childId === child.id && task.actionId === 'checkinChild');
          if (checkinTask) {
            //console.log("Gefilterter übrigbleibender checkinTask für not feeded children:", checkinTask);

            // Berechne die Summe der timer_in_min für Einträge mit categoryId: "noMeal"
            let timerSum = 0;
            let latestTimerSetAt = null;
            if (checkinTask.timers && Array.isArray(checkinTask.timers)) {
              checkinTask.timers.forEach(timer => {
                if (timer.categoryId === "noMeal") {
                  timerSum += timer.timer_in_min;
                  const timerSetAt = new Date(timer.timer_set_at._seconds * 1000);
                  if (!latestTimerSetAt || timerSetAt > latestTimerSetAt) {
                    latestTimerSetAt = timerSetAt;
                  }
                }
              });
            }

            // Falls die Summe der timer_in_min größer als 0 ist, zur neuesten timer_set_at hinzufügen
            let checkinTime = new Date(checkinTask.executed_at._seconds * 1000);
            if (timerSum > 0 && latestTimerSetAt) {
              //console.log(`Zur neuesten timer_set_at von ${latestTimerSetAt} werden ${timerSum} Minuten hinzugefügt.`);
              checkinTime = new Date(latestTimerSetAt.getTime() + timerSum * 60 * 1000);
              //console.log(`Neue checkinTime: ${checkinTime}`);
            }

            //console.log(checkinTime);
            //console.log(now);

            if (now > checkinTime) {
              overdue.noMeal.push({
                categoryId: "noMeal",
                childId: child.id,
                childName: checkinTask.childName,
                actionId: checkinTask.actionId,
                taskId: checkinTask.taskId,
                executed_at: checkinTask.executed_at
              });
            }
          } 
        }
      });
    } else {
      //console.log("Aktuelle Zeit liegt außerhalb des Triggerzeitraums.");
    }
  }
};


const checkDiaperAfterCheckin = (kitaOperationsTasks, attendingChildren, overdue) => {
  if (kitaOperationsTasks) {
    const now = new Date();
    const groupedTasks = {};

    // Gruppiere die Tasks nach childId
    kitaOperationsTasks.forEach(task => {
      const { childId, actionId, executed_at, taskId, childName, timers } = task;
      if (!groupedTasks[childId]) {
        groupedTasks[childId] = [];
      }
      groupedTasks[childId].push({ actionId, executed_at, taskId, childName, timers });
    });

    attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.forEach(child => {
      // Kinder mit nappyFree = true, checkout = true oder isAttending = false überspringen
      if (child.nappyFree || child.checkout || !child.isAttending) {
        return;
      }

      const tasks = groupedTasks[child.id];
      if (tasks) {
        // Sortiere die Tasks nach Ausführungszeit, um den neuesten Task zu erhalten
        tasks.sort((a, b) => new Date(b.executed_at._seconds * 1000) - new Date(a.executed_at._seconds * 1000));

        // Überspringe das Kind, wenn es bereits einen Task mit actionId 'changeDiaper' hat
        if (tasks.some(task => task.actionId === 'changeDiaper')) {
          return;
        }

        // Überspringe das Kind, wenn der neueste Task die actionId 'sleep' hat
        if (tasks[0].actionId === 'sleep') {
          return;
        }

        const checkinTask = tasks.find(task => task.actionId === 'checkinChild');
        if (checkinTask) {
          //console.log("Gefilterter übrigbleibender checkinTask:", checkinTask);
          let checkinTime = new Date(checkinTask.executed_at._seconds * 1000);

          // Berechne die Summe der timer_in_min für Einträge mit categoryId: "noDiaperChange"
          let timerSum = 0;
          if (checkinTask.timers && Array.isArray(checkinTask.timers)) {
            checkinTask.timers.forEach(timer => {
              if (timer.categoryId === "noDiaperChange") {
                timerSum += timer.timer_in_min;
              }
            });
          }

          // Falls die Summe der timer_in_min größer als 0 ist, zur checkinTime hinzufügen
          if (timerSum > 0) {
            //console.log(`Zur ursprünglichen checkinTime von ${checkinTime} werden ${timerSum} Minuten hinzugefügt.`);
            checkinTime = new Date(checkinTime.getTime() + timerSum * 60 * 1000);
            //console.log(`Neue checkinTime: ${checkinTime}`);
          }

          const ninetyMinutesLater = new Date(checkinTime.getTime() + 90 * 60 * 1000);
          //console.log(checkinTime);
          //console.log(ninetyMinutesLater);
          //console.log(now);

          if (now > ninetyMinutesLater) {
            overdue.noDiaperChange.push({
              categoryId: "noDiaperChange",
              childId: child.id,
              childName: checkinTask.childName,
              actionId: checkinTask.actionId,
              taskId: checkinTask.taskId,
              executed_at: checkinTask.executed_at
            });
          }
        }
      }
    });
  }
};




const calculateOverdueTasks = (kitaOperationsTasks, attendingChildren) => {
  const overdue = {
    changeDiaper: [],
    noMeal: [],
    noDiaperChange: []
  };
  const latestExecutions = {}; // Objekt zur Verfolgung der jüngsten Ausführungszeiten für jede Kind-ID und Aktion-ID
  let timerDurationSum = 0;

  // Neue Funktion aufrufen, um spezifische Uhrzeit-Aufgaben zu überprüfen
  checkForSpecificTimeTasks(kitaOperationsTasks, attendingChildren, overdue);
  // Neue Funktion aufrufen, um Kinder zu ermitteln die nach 90 min nach Checkin noch kein Windelwechsel hatten
  checkDiaperAfterCheckin(kitaOperationsTasks, attendingChildren, overdue);

  if (kitaOperationsTasks) {
    //console.log(kitaOperationsTasks);
    kitaOperationsTasks.forEach(task => {
      const { childId, actionId, executed_at, timers } = task;
      const executionTime = new Date(executed_at._seconds * 1000);

      // Find the child
      const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);

      // Skip the task if the child is checked out
      if (child && child.checkout) {
        return;
      }

      // Only update latest execution times for 'changeDiaper' tasks
      if (actionId === 'changeDiaper') {
        // Überprüfen, ob es bereits eine Ausführung für dieses Kind und diese Aktion gibt
        if (latestExecutions[childId] && latestExecutions[childId][actionId]) {
          const latestExecution = latestExecutions[childId][actionId];
          // Wenn die aktuelle Ausführung jünger ist als die bisherige, ersetzen Sie sie
          if (executionTime > latestExecution) {
            // Berücksichtigen Sie timerDurationSum, wenn Timer-Einträge vorhanden sind
            timerDurationSum = timers && timers.length > 0 ? timers.reduce((sum, timer) => sum + timer.timer_in_min, 0) : 0;
            //console.log(`Gesamtsumme der Timer-Dauern: ${timerDurationSum}`);
            latestExecutions[childId][actionId] = new Date(executionTime.getTime() + timerDurationSum * 60 * 1000);
            
            //latestExecutions[childId][actionId] = executionTime;
            //console.log(`Aktualisiert: Kind-ID ${childId}, Aktion-ID ${actionId}, Ausführungszeit: ${executionTime}`);
          } else {
            //console.log(`Übersprungen: Kind-ID ${childId}, Aktion-ID ${actionId}, Ausführungszeit: ${executionTime}`);
            return; // Überspringen Sie den aktuellen Eintrag, wenn er älter ist als die bisherige
          }
        } else {
          // Wenn es keine vorherige Ausführung für dieses Kind und diese Aktion gibt, setzen Sie sie als die jüngste ein
          if (!latestExecutions[childId]) latestExecutions[childId] = {};
          // Berücksichtigen Sie timerDurationSum, wenn Timer-Einträge vorhanden sind
          timerDurationSum = timers && timers.length > 0 ? timers.reduce((sum, timer) => sum + timer.timer_in_min, 0) : 0;
          //console.log(`Gesamtsumme der Timer-Dauern: ${timerDurationSum}`);
          latestExecutions[childId][actionId] = new Date(executionTime.getTime() + timerDurationSum * 60 * 1000);
          
          //latestExecutions[childId][actionId] = executionTime;
          //console.log(`Neu hinzugefügt: Kind-ID ${childId}, Aktion-ID ${actionId}, Ausführungszeit: ${executionTime}`);
        }
      }

      let timerDuration = 0;
      if (actionId === 'changeDiaper') {
        timerDuration = 160 * 60 * 1000; // 3 Stunden in Millisekunden
      }

      // Hinzufügen von timerDurationSum zur executionTime, falls vorhanden
      if (timerDurationSum > 0) {
        executionTime.setTime(executionTime.getTime() + timerDurationSum * 60 * 1000);
        //console.log(`NEUE EXECUTION Time: ${executionTime}`);
      }

      const expirationTime = new Date(executionTime.getTime() + timerDuration);
      //console.log(`Expiration Time: ${expirationTime}`);
      //console.log(`Aktuelle Zeit: ${new Date()}`);

      // Überprüfen, ob die Aufgabe überfällig ist
      // Use the handler based on actionId or use the default handler
      const handler = actionHandlers[actionId] || actionHandlers.defaultHandler;
      const result = handler(task, expirationTime);
      if (result) {
        if (result.actionId === 'changeDiaper') {
          overdue.changeDiaper.push(result); // Task in die entsprechende Kategorie pushen
        } 
        // Weitere Kategorien können hier hinzugefügt werden
      }
    });
  }

  console.log("Overdue tasks wurden berechnet und aktualisiert: ", overdue);
  return overdue;
};

const OverdueTasksCalculator = () => {
  const { kitaOperationsTasks, attendingChildren, updateOverdueTasks } = useContext(TerminalContext);

  useEffect(() => {
    const calculateAndSetOverdueTasks = () => {
      const calculatedOverdueTasks = calculateOverdueTasks(kitaOperationsTasks, attendingChildren);
      updateOverdueTasks(calculatedOverdueTasks); // Überfällige Aufgaben im TerminalContext aktualisieren
    };

    let interval = null;

    if (auth.currentUser) {

      // Initiale Berechnung der überfälligen Aufgaben
      calculateAndSetOverdueTasks();

      // Intervall setzen, um die Funktion alle 60 Sekunden aufzurufen
      interval = setInterval(calculateAndSetOverdueTasks, 60 * 1000);
    }

    // Clean-up-Funktion für das Intervall
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitaOperationsTasks, auth.currentUser]);

  return null; // Die Komponente gibt nichts gerendertes zurück
};

export default OverdueTasksCalculator;
