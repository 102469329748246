import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TerminalContext from '../TerminalContext';
import SignOperation from '../shared/SignOperation';
import ShowOperations from '../shared/ShowOperations';
import { auth } from '../firebase-config';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import '../styles/Operations.css';
import '../styles/CheckoutChild.css';
import getIconPath from '../shared/config/actionIcons'; // Importiere getIconPath
import ACTION_DESCRIPTIONS from '../shared/config/actionTypes';

const CheckoutChild = () => {
  const { attendingChildren, employeeData, kitaOperationsTasks, overdueTasks, operationSavedTrigger, updateOperationSavedTrigger } = useContext(TerminalContext);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [operationSaved, setOperationSaved] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); // Zustand für die Checkbox
  const [modalStep, setModalStep] = useState('preparation'); // Zustand für den Modalschritt
  const navigate = useNavigate(); // useNavigate hook


  const handleChildClick = (childId) => {
    const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);
    // console.log('Clicked child:', child); // Debugging log
    if (!child.checkin) {
      // console.log('Navigating to /checkin-child because checkin is false'); // Debugging log
      navigate('/checkin-child'); // Redirect to /checkin-child if the child is not checked in
      return;
    }
  
    if (selectedChildren.includes(childId)) {
      setSelectedChildren([]);
    } else {
      setSelectedChildren([childId]);
    }
  };
  

  const handleSaveTask = () => {
    if (selectedChildren.length === 0) {
      setErrorMessage('Bitte wählen Sie ein Kind aus.');
    } else {
      const selectedChild = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0]);
      if (selectedChild && selectedChild.checkin && selectedChild.checkout) {
        setErrorMessage('Das Kind ist bereits ausgecheckt.');
      } else {
        setShowInfoModal(true);
        setModalStep('preparation'); // Setze den Modalschritt auf Vorbereitung
        setTermsAccepted(false); // Setze die Checkbox zurück
        setErrorMessage('');
      }
    }
  };

  const handleSignOperationClose = (wasSuccessful) => {
    if (wasSuccessful) {
      setSelectedChildren([]);
      setOperationSaved(true);
      updateOperationSavedTrigger(!operationSavedTrigger);
    }
  };

  const prepareDataForSignOperation = () => {
    return selectedChildren.map(childId => {
      const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);
      const payload = {
        changed_at: new Date().toISOString(),
        changed_by: auth.currentUser.uid,
        checkout_at: new Date().toISOString(),
        checkout_by: auth.currentUser.uid,
        childRef: `/child/${child.id}`,
        givenDate: new Date(),
      };
      return {
        childId: child.id,
        childRef: child.childRef,
        action: "checkoutChild",
        payload: payload
      };
    });
  };

  
  const selectedChild = selectedChildren.length > 0
    ? attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0])
    : null;


    const childrenList = useMemo(() => {
      return (attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || []).filter(child => child.isAttending);
    }, [attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance]);
  

  const filteredOperations = useMemo(() => {
    if (selectedChild && kitaOperationsTasks) {
      return kitaOperationsTasks
        .filter(op => op.childId === selectedChild.id)
        .sort((a, b) => a.executed_at._seconds - b.executed_at._seconds);
    }
    return [];
  }, [selectedChild, kitaOperationsTasks]);

  console.log("KitaOperationsTasks: ", kitaOperationsTasks);

  useEffect(() => {
    const interval = setInterval(() => {
      // Überprüfe für jedes Kind, ob es in einer der Kategorien von overdueTasks ist
      childrenList.forEach(child => {
        const isOverdue = Object.values(overdueTasks).some(categoryTasks =>
          categoryTasks.some(task => task.childId === child.id)
        );
        const childElement = document.querySelector(`.child-tile[data-child-id="${child.id}"]`);
        if (childElement) {
          if (isOverdue) {
            childElement.classList.add('overdue'); // Füge Klasse für Überfälligkeit hinzu
          } else {
            childElement.classList.remove('overdue'); // Entferne Klasse für Überfälligkeit
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval); // Aufräumen beim Komponenten-Unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdueTasks]);

  if (!employeeData || !attendingChildren || !attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.length === 0) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Daten werden geladen...</p>
      </div>
    );
  }

  const sortedChildrenList = [...childrenList].sort((a, b) => {
    // Zuerst die Kinder, die checkin=true und checkout=false haben
    if (a.checkin && !a.checkout && (!b.checkin || b.checkout)) return -1;
    if (b.checkin && !b.checkout && (!a.checkin || a.checkout)) return 1;

    // Dann die Kinder, die checkin=null und checkout=null haben
    if ((a.checkin === null && a.checkout === null) && (b.checkin !== null || b.checkout !== null)) return -1;
    if ((b.checkin === null && b.checkout === null) && (a.checkin !== null || a.checkout !== null)) return 1;

    // Schließlich die Kinder, die checkin=true und checkout=true haben
    if (a.checkin && a.checkout && (!b.checkin || !b.checkout)) return 1;
    if (b.checkin && b.checkout && (!a.checkin || !a.checkout)) return -1;

    return 0; // Keine Änderung für andere Fälle
  });
  
  
  const handleModalClose = () => {
    setShowInfoModal(false);
    setShowSignModal(false);
    // Reset the states when the modal is closed
    setSelectedChildren([]);
    setModalStep('preparation');
    setTermsAccepted(false);
  };


  const isAlreadyCheckedOut = selectedChild && selectedChild.checkout;

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Check Out</h2>
      <p className="text-center mb-4">Bestätige die Abholung eines Kindes:</p>
      {errorMessage && <div className="alert alert-warning text-center">{errorMessage}</div>}
      <div className="child-tiles-container">
        {sortedChildrenList.map((child) => (
          <div 
            key={child.id} 
            className={`child-tile ${selectedChildren.includes(child.id) ? 'selected' : ''} ${child.checkout && child.checkin ? 'checked-out' : ''} ${!child.checkin && !child.checkout ? 'not-checkin' : ''}`}
            onClick={() => handleChildClick(child.id)}
            data-child-id={child.id}
          >
            <div className="d-flex flex-column align-items-center p-2">
              <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
              <p className="m-0">{child.firstname} {child.lastname}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-primary" onClick={handleSaveTask} disabled={isAlreadyCheckedOut}>
          Kind auschecken
        </button>
      </div>

      <ShowOperations 
        action="checkoutChild"
        givenDate={new Date().toISOString().split('T')[0]} 
        operationSaved={operationSaved} 
        resetOperationSaved={() => setOperationSaved(false)}
      />

      <SignOperation
        show={showSignModal}
        onHide={() => handleModalClose()}
        onSuccessfulSubmit={() => handleSignOperationClose(true)}
        operationsData={prepareDataForSignOperation()}
      />

      <Modal show={showInfoModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalStep === 'preparation' ? 'Checkout Vorbereitung' : 'Kind auschecken'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalStep === 'preparation' ? (
            <>
              {selectedChild && (
                <>
                  <div className="d-flex flex-column align-items-center">
                    <h5>{selectedChild.firstname} {selectedChild.lastname}</h5>
                    <img src={selectedChild.profilePic} alt={`${selectedChild.firstname} ${selectedChild.lastname}`} className="rounded-circle mb-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Check 
                      type="checkbox"
                      label={`Ich bestätige hiermit, dass ${selectedChild.firstname} ${selectedChild.lastname} aufgefrischt und sauber gemacht wurde für die Übergabe.`}
                      checked={termsAccepted}
                      onChange={() => setTermsAccepted(!termsAccepted)}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {selectedChild && (
                <div className="d-flex flex-column align-items-center">
                  <h5>{selectedChild.firstname} {selectedChild.lastname}</h5>
                  <img src={selectedChild.profilePic} alt={`${selectedChild.firstname} ${selectedChild.lastname}`} className="rounded-circle mb-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </div>
              )}
              <h5>Heutige Aktivitäten</h5>
              <div className="operations-list">
                {filteredOperations.length > 0 ? (
                  filteredOperations.map((op, index) => (
                    <div key={index} className="operation-entry-checkout">
                      <div className="operation-date-checkout">
                        {new Date(op.executed_at._seconds * 1000).toLocaleTimeString('de-DE', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </div>
                      <div className="operation-icon-container-checkout">
                        <div className="operation-icon-checkout" style={{ backgroundImage: `url(${getIconPath(op.actionId, op.mealType)})` }}></div>
                      </div>
                      {/* Text der Operation */}
                      <div className={`operation-text-checkout ${selectedChild === op.childId ? 'highlight' : ''}`}>
                        {(() => {
                          switch (op.actionId) {
                            case 'changeDiaper':
                              return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.changeDiaper} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                            case 'checkinChild':
                              return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.checkinChild} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                            case 'checkoutChild':
                              return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.checkoutChild} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                            case 'feedChild':
                              if (op.mealType === 'Essen') {
                                const performance = op.eatPerformance === 'bad' ? 'weniger gut' : op.eatPerformance === 'good' ? 'gut' : 'sehr gut';
                                return (
                                  <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.feedChildEat} durch ${op.executedByName}.<br/><strong>Menge: ${performance}.</strong>${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />
                                );
                              } else if (op.mealType === 'Trinken') {
                                return (
                                  <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.feedChildDrink} durch ${op.executedByName}.<br/><strong>Menge: ${op.drinkAmount} ml.</strong>${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />
                                );
                              } else {
                                return <span dangerouslySetInnerHTML={{ __html: `Mahlzeit durch ${op.executedByName} durchgeführt.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                              }
                            case 'sleep':
                              return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.sleep} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                            case 'awakening':
                              return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.awakening} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                            default:
                              return <span dangerouslySetInnerHTML={{ __html: `${op.action} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                          }
                        })()}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-data-message">Keine Daten für den heutigen Tag gefunden.</p>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {modalStep === 'preparation' ? (
            <>
              <Button variant="secondary" onClick={handleModalClose}>
                Abbrechen
              </Button>
              <Button variant="primary" onClick={() => setModalStep('confirmation')} disabled={!termsAccepted}>
                Weiter
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={handleModalClose}>
                Abbrechen
              </Button>
              <Button variant="primary" onClick={() => { setShowInfoModal(false); setShowSignModal(true); }}>
                Weiter
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckoutChild;
