import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import RemindMeLater from './ops/RemindMeLater';
import TerminalContext from './TerminalContext';
import './styles/SoundAlert.css';

const SoundAlert = ({ onClose, onAlarmStart, onAlarmStop, overdueTasks }) => {
  const { attendingChildren, operationSavedTrigger, updateOperationSavedTrigger } = useContext(TerminalContext);
  // eslint-disable-next-line no-unused-vars
  const [autoplayAllowed, setAutoplayAllowed] = useState(false);
  const [alarmStarted, setAlarmStarted] = useState(false);
  const [alarmSent, setAlarmSent] = useState(false); // Zustand, ob der Alarm bereits gesendet wurde
  const [selectedTasks, setSelectedTasks] = useState([]);

  useEffect(() => {
    /* old code
    let audio = new Audio(`${process.env.PUBLIC_URL}/alarm.mp3`);

    const playAudio = async () => {
      try {
        console.log("Alarm gestartet innerhalb soundalert und alarmstarted = false");
        await audio.play();
        setAutoplayAllowed(true); // Automatische Wiedergabe erfolgreich gestartet
        setAlarmStarted(true);
        onAlarmStart(); // Timer stoppen, wenn Alarm startet
      } catch (error) {
        console.error('Automatische Wiedergabe nicht erlaubt. Bitte erlauben Sie die Wiedergabe in den Browsereinstellungen.');
        setAutoplayAllowed(false); // Automatische Wiedergabe blockiert
      }
    };
    

  const handleVisibilityChange = () => {
    if (!document.hidden && audio && !alarmStarted) {
      // Seite ist wieder sichtbar und der Alarm wurde bereits gestartet
      // Überprüfen Sie, ob der Alarm noch läuft und spielen Sie ihn gegebenenfalls erneut ab
      if (!audio.paused) {
        audio.play();
      }
    }
  };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (!alarmStarted) {
      playAudio();
    }

    return () => {
      if (audio) {
        audio.pause(); // Stoppe den Sound beim Entfernen der Komponente
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    */

    // Ersetzt durch Nachricht an Service Worker
    const requestPlayAudio = () => {
      if (!alarmSent && navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({type: 'playAudio'});
        console.log("Request to play audio sent to service worker.");
        setAutoplayAllowed(true);
        setAlarmSent(true);  // Setzen, dass der Alarm bereits gesendet wurde
        setAlarmStarted(true);
        onAlarmStart();
      } else {
        console.error("Service worker not registered or no controller available.");
        setAutoplayAllowed(false);
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        requestPlayAudio();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (!alarmStarted) {
      requestPlayAudio();
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    
  const requestStopAudio = () => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({type: 'stopAudio'});
      console.log("Request to stop audio sent to service worker.");
    }
  };

  const handleClose = () => {
    const nextCategory = getNextCategory();
    if (nextCategory) {
      setCurrentCategory(nextCategory);
    } else {
      onClose();
      onAlarmStop(); // Timer starten, wenn Alarm gestoppt wird in App.js Komponente
      requestStopAudio();
      setAlarmSent(false); // Zurücksetzen des Zustands, wenn der Modal geschlossen wird
      setAlarmStarted(false); // Alarm wurde hier in der Komponente gestoppt
      triggerRefresh();
    }
    setSelectedTasks([]);
  };
  
  const triggerRefresh = () => {
    updateOperationSavedTrigger(!operationSavedTrigger);
  };

  //Initiale Kategorie festlegen
  const [currentCategory, setCurrentCategory] = useState(
    overdueTasks.noDiaperChange.length > 0 ? 'noDiaperChange' : 
    overdueTasks.changeDiaper.length > 0 ? 'changeDiaper' :
    overdueTasks.noMeal.length > 0 ? 'noMeal' : ''
  );

  //Nächste Kategorie bestimmen
  const getNextCategory = () => {
    if (currentCategory === 'noDiaperChange') {
      if (overdueTasks.changeDiaper.length > 0) {
        return 'changeDiaper';
      } else if (overdueTasks.noMeal.length > 0) {
        return 'noMeal';
      }
    } else if (currentCategory === 'changeDiaper' && overdueTasks.noMeal.length > 0) {
      return 'noMeal';
    }
    return '';
  };


  const formatTime = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  //Titel dynamisch anpassen
  const getTitle = (category) => {
    switch (category) {
      case 'noDiaperChange':
        return "Windelwechsel ausstehend";
      case 'changeDiaper':
        return "Windeln wechseln ist fällig";
      case 'noMeal':
        return "Mahlzeit ausstehend";
      default:
        return "Aufgaben fällig";
    }
  };

  
  const toggleTaskSelection = (task) => {
    setSelectedTasks((prevSelectedTasks) => {
      if (prevSelectedTasks.some(selectedTask => selectedTask.childId === task.childId)) {
        return prevSelectedTasks.filter(selectedTask => selectedTask.childId !== task.childId);
      } else {
        return [...prevSelectedTasks, task];
      }
    });
  };


  return (
    <Modal show={true} centered>
      <Modal.Header>
        <Modal.Title className="text-center">{getTitle(currentCategory)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {currentCategory === 'noDiaperChange' && overdueTasks.noDiaperChange.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-center">            
            <p>Folgenden Kindern wurde heute die Windel noch gar nicht gewechselt:</p>
            {overdueTasks.noDiaperChange.map((task, index) => {
              const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === task.childId);
              return child ? (
                <div
                  key={index}
                  className={`d-flex flex-column align-items-center p-2 child-tile ${selectedTasks.some(selectedTask => selectedTask.childId === task.childId) ? 'selected' : ''}`}
                  onClick={() => toggleTaskSelection(task)}
                >
                  <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                  <p className="m-0">{child.firstname}</p>
                </div>
              ) : null;
            })}
          </div>
        ) : currentCategory === 'changeDiaper' && overdueTasks.changeDiaper.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-center">
            {overdueTasks.changeDiaper.map((task, index) => {
              const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === task.childId);
              return child ? (
                <div
                  key={index}
                  className={`d-flex flex-column align-items-center p-2 child-tile ${selectedTasks.some(selectedTask => selectedTask.childId === task.childId) ? 'selected' : ''}`}
                  onClick={() => toggleTaskSelection(task)}
                >
                  <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                  <p className="m-0">{child.firstname}</p>
                  <p className="m-0">letzter Wechsel</p>
                  <p className="m-0">{formatTime(task.executed_at)}</p>
                </div>
              ) : null;
            })}
          </div>
        ) : currentCategory === 'noMeal' && overdueTasks.noMeal.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-center">
            <p>Folgende Kinder haben noch keine Mahlzeit erhalten:</p>
            {overdueTasks.noMeal.map((task, index) => {
              const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === task.childId);
              return child ? (
                <div
                  key={index}
                  className={`d-flex flex-column align-items-center p-2 child-tile ${selectedTasks.some(selectedTask => selectedTask.childId === task.childId) ? 'selected' : ''}`}
                  onClick={() => toggleTaskSelection(task)}
                >
                  <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                  <p className="m-0">{child.firstname}</p>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <p>Keine überfälligen Aufgaben</p>
        )}
      </Modal.Body>
      {currentCategory === 'changeDiaper' && overdueTasks.changeDiaper.length > 0 && (
        <Modal.Footer>
            <RemindMeLater overdueTasksCategory={selectedTasks} onSuccessfulSubmit={handleClose} />
          </Modal.Footer>
        )}
        {currentCategory === 'noDiaperChange' && overdueTasks.noDiaperChange.length > 0 && (
          <Modal.Footer>
            <RemindMeLater overdueTasksCategory={selectedTasks} onSuccessfulSubmit={handleClose} />
          </Modal.Footer>
        )}
        {currentCategory === 'noMeal' && overdueTasks.noMeal.length > 0 && (
          <Modal.Footer>
            <RemindMeLater overdueTasksCategory={selectedTasks} onSuccessfulSubmit={handleClose} />
          </Modal.Footer>
        )}
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} style={{ width: '100%' }}>
          Ok, wird gleich ausgeführt
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SoundAlert;