import React, { useState, useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { auth } from './../firebase-config';
import TerminalContext from '../TerminalContext';
import { useNavigate } from "react-router-dom";
import '../styles/Navbar.css';

const MyNavbar = ({ onLogout }) => {
  const [expanded, setExpanded] = useState(false);
  const { selectedTerminal, operationSavedTrigger, updateOperationSavedTrigger } = useContext(TerminalContext);
  const navigate = useNavigate();

  const handleLogoClick = (event) => {
    event.preventDefault(); // Verhindert die Standardnavigation
    console.log("operationSavedTrigger status:", operationSavedTrigger);
    updateOperationSavedTrigger(!operationSavedTrigger);
    navigate('/'); // Navigiert zur Startseite
  };

  return (
    <Navbar className="custom-nav" expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Navbar.Brand as={Link} to="/">
        <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={selectedTerminal?.kitaData.logo} alt={`Logo von ${selectedTerminal?.kitaData.name}`} style={{ maxWidth: '100%', maxHeight: '100px' }} />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-light" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {auth.currentUser ? (
            <>
              <Nav.Link as={Link} to="/" onClick={() => { setExpanded(false); }} className="custom-nav-link">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/" onClick={onLogout} className="custom-nav-link">
                Logout
                <FontAwesomeIcon icon={faSignOutAlt} className="custom-icon" />
              </Nav.Link>
            </>
          ) : (
            <>
              {/* Füge hier zusätzliche Links oder Elemente für nicht authentifizierte Benutzer hinzu */}
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
