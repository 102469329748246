import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TerminalContext from '../TerminalContext';
import SignOperation from '../shared/SignOperation';
import ShowOperations from '../shared/ShowOperations';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import '../styles/Operations.css';
import '../styles/Meal.css';
import getIconPath from '../shared/config/actionIcons'; // Importiere getIconPath

const Sleep = () => {
  const { attendingChildren, employeeData, overdueTasks, latestActivity } = useContext(TerminalContext);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Neuer State für Fehlermeldung
  const [operationSaved, setOperationSaved] = useState(false); // um ShowOperations nach erfolgreichem speichern neu zu laden
  const navigate = useNavigate(); // useNavigate hook

  
  const [type, setType] = useState('');
  const [amount, setAmount] = useState(0);
  const [performance, setPerformance] = useState('');
  const [comment, setComment] = useState('');

  const handleChildClick = (childId) => {
    const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);
    // console.log('Clicked child:', child); // Debugging log
    if (!child.checkin) {
      // console.log('Navigating to /checkin-child because checkin is false'); // Debugging log
      navigate('/checkin-child'); // Redirect to /checkin-child if the child is not checked in
      return;
    }
    if (child.checkout) {
      return;
    }
    if (selectedChildren.includes(childId)) {
      setSelectedChildren([]);
    } else {
      setSelectedChildren([childId]);
    }
  };

  const handleSaveTask = () => {
    if (selectedChildren.length === 0) {
      setErrorMessage('Bitte wählen Sie ein Kind aus.');
    } else {
      //const selectedChild = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0]);
      setShowInfoModal(true); // Modal öffnen, wenn mindestens eine Kachel ausgewählt wurde
      setErrorMessage(''); // Fehlermeldung zurücksetzen
    }
  };

  const handleSignOperationClose = (wasSuccessful) => {
    if (wasSuccessful) {
      setSelectedChildren([]); // Deselektiert alle Kinder
      setOperationSaved(true); // Setze operationSaved auf true, wenn der Auftrag erfolgreich gespeichert wurde
    }
  };

  const prepareDataForSignOperation = () => {
    return selectedChildren.map(childId => {
      const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === childId);
      return {
        childRef: child.childRef,
        action: "feedChild",
        mealType: type,
        drinkAmount: amount,
        eatPerformance: performance,
        comment: comment
      };
    });
  };

  const childrenList = (attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || []).filter(child => child.isAttending);
  
  useEffect(() => {
    const interval = setInterval(() => {
      // Überprüfe für jedes Kind, ob es in einer der Kategorien von overdueTasks ist
      childrenList.forEach(child => {
        const isOverdue = Object.values(overdueTasks).some(categoryTasks =>
          categoryTasks.some(task => task.childId === child.id)
        );
        const childElement = document.querySelector(`.child-tile[data-child-id="${child.id}"]`);
        if (childElement) {
          if (isOverdue) {
            childElement.classList.add('overdue'); // Füge Klasse für Überfälligkeit hinzu
          } else {
            childElement.classList.remove('overdue'); // Entferne Klasse für Überfälligkeit
          }
        }
      });
    }, 1000);

    return () => clearInterval(interval); // Aufräumen beim Komponenten-Unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdueTasks]);

  useEffect(() => {
    // Überprüfe für jedes Kind, ob die Aktivität "sleep" ist
    childrenList.forEach(child => {
      const latestActivityForChild = latestActivity.find(activity => activity.childId === child.id);
      const childElement = document.querySelector(`.child-tile[data-child-id="${child.id}"]`);
      if (childElement) {
        if (latestActivityForChild && latestActivityForChild.latestAction === 'sleep') {
          childElement.classList.add('disabled-sleep'); // Füge Klasse für "sleep" hinzu
        } else {
          childElement.classList.remove('disabled-sleep'); // Entferne Klasse für "sleep"
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenList]);
  

  // Überprüfen, ob die Liste der Kinder noch geladen wird
  // Das könnte bedeuten, dass attendingChildren noch nicht gesetzt wurde oder leer ist.
  // Beachte: Du solltest prüfen, ob die spezifische Eigenschaft, auf die du zugreifst, vorhanden ist.
  // In diesem Beispiel gehe ich davon aus, dass 'combinedChildrenListForCalculatedAndCheckedAttendance' die relevante Eigenschaft ist.
  if (!employeeData || !attendingChildren || !attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.length === 0) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Daten werden geladen...</p>
      </div>
    );
  }

  const sortedChildrenList = [...childrenList].sort((a, b) => {
    // Zuerst die Kinder, die checkin=true und checkout=false haben
    if (a.checkin && !a.checkout && (!b.checkin || b.checkout)) return -1;
    if (b.checkin && !b.checkout && (!a.checkin || a.checkout)) return 1;

    // Dann die Kinder, die checkin=null und checkout=null haben
    if ((a.checkin === null && a.checkout === null) && (b.checkin !== null || b.checkout !== null)) return -1;
    if ((b.checkin === null && b.checkout === null) && (a.checkin !== null || a.checkout !== null)) return 1;

    // Schließlich die Kinder, die checkin=true und checkout=true haben
    if (a.checkin && a.checkout && (!b.checkin || !b.checkout)) return 1;
    if (b.checkin && b.checkout && (!a.checkin || !a.checkout)) return -1;

    return 0; // Keine Änderung für andere Fälle
  });

  const selectedChild = selectedChildren.length > 0
  ? attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(c => c.id === selectedChildren[0])
  : null;


  const handleModalClose = () => {
    setShowInfoModal(false);
    setShowSignModal(false);
    // Reset the states when the modal is closed
    setSelectedChildren([]);
    setType('');
    setAmount(0);
    setPerformance('');
    setComment('');
  };

  const handleOptionClick = (setter, value) => {
    setter(value);
    setAmount(0); // Setze die Menge zurück, wenn der Typ geändert wird
    setPerformance(''); 
  };

  const handlePerformanceClick = (value) => {
    setPerformance(value);
  };

  const getAmountText = () => {
    if (type === 'Essen') {
      switch (performance) {
        case 'bad':
          return 'weniger gut gegessen';
        case 'good':
          return 'gut gegessen';
        case 'veryGood':
          return 'sehr gut gegessen';
        default:
          return '';
      }
    } else if (type === 'Trinken' && amount > 0) {
      return `${amount} ml getrunken`;
    }
    return '';
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Essenszeit</h2>
      <p className="text-center mb-4">Diesen Kindern habe ich eine Mahlzeit gegeben:</p>
      {errorMessage && <div className="alert alert-warning text-center">{errorMessage}</div>}
      <div className="child-tiles-container">
        {sortedChildrenList.map((child) => (
          <div 
            key={child.id} 
            className={`child-tile ${selectedChildren.includes(child.id) ? 'selected' : ''} ${child.checkout && child.checkin ? 'checked-out' : ''} ${!child.checkin && !child.checkout ? 'not-checkin' : ''}`}
            onClick={() => handleChildClick(child.id)}
            data-child-id={child.id}
          >
            <div className="d-flex flex-column align-items-center p-2">
              <img src={child.profilePic} alt={`${child.firstname} ${child.lastname}`} className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
              <p className="m-0">{child.firstname} {child.lastname}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-primary" onClick={handleSaveTask}>
          Auftrag speichern
        </button>
      </div>

      {/* Hier fügst du ShowOperations ein, übergib operationSaved und setOperationSaved */}
      <ShowOperations 
        action="feedChild" 
        givenDate={new Date().toISOString().split('T')[0]} 
        operationSaved={operationSaved} 
        resetOperationSaved={() => setOperationSaved(false)}
      />


      <SignOperation
      show={showSignModal}
      onHide={() => handleModalClose()}
      onSuccessfulSubmit={() => handleSignOperationClose(true)}
      operationsData={prepareDataForSignOperation()}
    />

    <Modal show={showInfoModal} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Mahlzeit erfassen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedChild && (
          <div className="d-flex flex-column align-items-center">
            <h5>{selectedChild.firstname} {selectedChild.lastname}</h5>
            <img src={selectedChild.profilePic} alt={`${selectedChild.firstname} ${selectedChild.lastname}`} className="rounded-circle mb-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
          </div>
        )}
        <div className="modal-category">
          <h5>Typ:</h5>
          <div className="d-flex justify-content-center">
            <div className={`modal-option-tile ${type === 'Essen' ? 'selected' : ''}`} onClick={() => handleOptionClick(setType, 'Essen')}>
              Essen
            </div>
            <div className={`modal-option-tile ${type === 'Trinken' ? 'selected' : ''}`} onClick={() => handleOptionClick(setType, 'Trinken')}>
              Trinken
            </div>
          </div>
        </div>
        <div className="modal-category">
          <h5>Menge: {getAmountText()}</h5>
          <div className="d-flex justify-content-center">
            {type === 'Essen' && (
              <>
                <div className={`modal-amount-tile ${performance === 'bad' ? 'selected' : ''}`} onClick={() => handlePerformanceClick('bad')}>
                  <img src={getIconPath('bad')} alt="Schlecht" />
                </div>
                <div className={`modal-amount-tile ${performance === 'good' ? 'selected' : ''}`} onClick={() => handlePerformanceClick('good')}>
                  <img src={getIconPath('good')} alt="Gut" />
                </div>
                <div className={`modal-amount-tile ${performance === 'veryGood' ? 'selected' : ''}`} onClick={() => handlePerformanceClick('veryGood')}>
                  <img src={getIconPath('verygood')} alt="Sehr gut" />
                </div>
              </>
            )}
            {type === 'Trinken' && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <img src={getIconPath('emptybottle')} alt="leere Flasche" className="bottle-icon" />
                <Form.Control
                  type="range"
                  min="0"
                  max="250"
                  step="10"
                  value={amount}
                  onChange={(e) => setAmount(parseInt(e.target.value, 10))}
                  className="slider"
                />
                <img src={getIconPath('fullbottle')} alt="ganze Flasche" className="bottle-icon" />
              </div>
            )}
          </div>
        </div>
        <div className="modal-category">
          <h5>Kommentar:</h5>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} rows="3" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={() => { setShowInfoModal(false); setShowSignModal(true); }}>
          Weiter
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  );
};

export default Sleep;
