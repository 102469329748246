import React, { useContext, useState, useEffect } from 'react';
import { auth } from './firebase-config';
import TerminalContext from './TerminalContext';
import SelectOperationHomeModal from './shared/SelectOperationHomeModal'; // Import der Modal-Komponente
import ShowOperations from './shared/ShowOperations';

import './styles/Home.css';

const Home = ({ currentDate }) => {
  const { selectedTerminal } = useContext(TerminalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState([]);
  const [operationSaved, setOperationSaved] = useState(false); // Zustand, der angibt, ob eine Operation gespeichert wurde
  const [givenDate, setGivenDate] = useState(new Date().toISOString().split('T')[0]); // Zustand für das gegebene Datum

  useEffect(() => {
    // Überprüfen, ob currentDate vorhanden und nicht leer ist
    if (currentDate && currentDate !== "") {
      //console.log("givendate == curentdate in home.js: ", currentDate);
      setGivenDate(currentDate); // Aktualisieren Sie das gegebene Datum mit currentDate aus den Props
    }
  }, [currentDate]);

  const optionsMap = {
    "Kinder": [
      { label: "Checkin", route: "/checkin-child" },
      { label: "Mahlzeit", route: "/meal" },
      { label: "Windeln", route: "/change-diaper" },
      { label: "Schlafen", route: "/sleep" },
      { label: "Aufwachen", route: "/awakening" },
      { label: "Checkout", route: "/checkout-child" },
      // ... weitere Optionen für Kinder
    ],
    "Kita": [
      { label: "hier wird gerade gebaut", route: "/" },
      // ... weitere Optionen für Kita
    ],
    "Mitarbeiter": [
      { label: "hier wird gerade gebaut", route: "/" },
      // ... weitere Optionen für Mitarbeiter
    ],
  };

  const onTileClick = (tile) => {
    if (optionsMap[tile]) {
      setModalOptions(optionsMap[tile]);
      setIsModalOpen(true);
    }
  };

  const renderTiles = () => {
    const tiles = ["Kinder", "Kita", "Mitarbeiter"];
    return tiles.map((tile, index) => (
      <div key={index} className="tile" onClick={() => onTileClick(tile)}>
        {tile}
      </div>
    ));
  };

  if (auth.currentUser && selectedTerminal.isActivatedTerminal) {
    return (
      <div className="container mt-5">        
        <div className="tiles-container">
          {renderTiles()}
        </div>
        {/* Hier fügst du ShowOperations ein, übergib operationSaved und resetOperationSaved */}
        <ShowOperations 
          action="none" 
          givenDate={givenDate}
          operationSaved={operationSaved}
          resetOperationSaved={() => setOperationSaved(false)}
        />
        <SelectOperationHomeModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)}
          options={modalOptions}
        />
    </div>
    );
  } else {
    return <div>Kein Terminaldaten vorhanden. Terminal ist deaktiviert. Bitte wenden Sie sich an Ihre Kitaleitung.</div>;
  }
};

export default Home;
