import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const SelectOperationHomeModal = ({ isOpen, onClose, options }) => {
  const navigate = useNavigate();

  const handleTileClick = (route) => {
    navigate(route);
    onClose(); // Schließt das Modal nach der Navigation
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Optionen wählen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-tiles">
          {options.map(({ label, route }) => (
            <div key={label} className="modal-tile" onClick={() => handleTileClick(route)}>
              {label}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectOperationHomeModal;
