import React, { useState, useContext } from 'react';
import TerminalContext from "./TerminalContext";
import { auth } from './firebase-config';

const RegisterDevice = () => {
  const { selectedTerminal, updateDeviceRegistrationStatus } = useContext(TerminalContext);
  const [deviceToken, setDeviceToken] = useState(localStorage.getItem('deviceToken'));
  const [deviceName, setDeviceName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  const handleRequestCode = async () => {
    setIsLoading(true);
    setErrorCode(null);
    try {
      const uid = auth.currentUser.uid;
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

      const response = await fetch(`${baseUrl}/register-device`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
        },
        body: JSON.stringify({ uid, deviceName })
      });

      if (response.ok) {
        setShowCodeInput(true);
      } else {
        setErrorCode("E-Mail konnte nicht zugestellt werden. Bitte erneut anfordern.");
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorCode("E-Mail konnte nicht zugestellt werden. Bitte erneut anfordern.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateCode = async () => {
    try {
      const uid = auth.currentUser.uid;
      const kitaId = selectedTerminal.kitaData.kitaId; 
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
  
      const response = await fetch(`${baseUrl}/verify-temp-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
        },
        body: JSON.stringify({ uid, kitaId, verificationCode })
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.isActive) {
          localStorage.setItem('deviceToken', data.deviceToken); // Speichern des deviceToken im localStorage
          setDeviceToken(data.deviceToken);
          updateDeviceRegistrationStatus(true);
        } else {
          setErrorCode("Gerät ist nicht aktiv. Bitte überprüfen Sie den Validierungsstatus.");
        }
      } else {
        const errorData = await response.json();
        if (errorData.errorCode === 'verificationCodeNotFound') {
          setErrorCode("Code nicht gefunden. Bitte nochmals versuchen.");
        } else if (errorData.errorCode === 'verificationCodeTimeOut') {
          setErrorCode("Ihr Code ist abgelaufen. Bitte neuen Code beantragen.");
          setShowCodeInput(false);
          setDeviceToken(null);
        } else {
          setErrorCode("Ein Fehler ist aufgetretten. Bitte versuchen Sie es nochmals."); // Andere Fehlermeldungen anzeigen
        }
      }
      
    } catch (error) {
      console.error('Error:', error);
      setErrorCode("Fehler bei der Code-Überprüfung. Bitte erneut versuchen.");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      {!deviceToken && !showCodeInput && (
        <div className="card" style={{ width: '350px' }}>
          <div className="card-body text-center">
            {isLoading ? (
              <p>Ein Code wird gerade gesendet...</p>
            ) : (
                <>
                {errorCode && <div className="alert alert-danger">{errorCode}</div>}
                <p>Registrieren Sie dieses Device.</p>
                <label htmlFor="deviceName" style={{ marginBottom: '10px' }}>Geben Sie einen Gerätenamen ein:</label>
                <input
                    id="deviceName"
                    type="text"
                    className="form-control"
                    placeholder="Gerätename"
                    value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)}
                    maxLength={50}
                    style={{ 
                    marginBottom: '15px', 
                    textAlign: 'center' // Text und Placeholder zentrieren
                    }}
                />
                <button 
                    className="btn btn-primary btn-block"
                    onClick={handleRequestCode}
                    disabled={isLoading}
                    style={{ marginTop: '10px' }}>
                    Code anfordern
                </button>
                </>
            )}
          </div>
        </div>
      )}
      {showCodeInput && (
        <div className="card" style={{ width: '350px' }}>
          <div className="card-body text-center">
            {errorCode && <div className="alert alert-danger">{errorCode}</div>}
            <input
              type="text"
              className="form-control text-center"
              placeholder="6-stelliger Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button 
              className="btn btn-primary btn-block mt-3"
              onClick={handleValidateCode}>
              Code validieren
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterDevice;
