import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import LoginForm from './LoginForm';
import RegisterDevice from './RegisterDevice';
import MyNavbar from './shared/navbar';
import Home from './home';
import Footer from './shared/footer';
import TerminalContext from './TerminalContext';
import { auth } from './firebase-config';
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { checkDeviceToken } from './shared/deviceTokenUtils';
import ChangeDiaper from './ops/ChangeDiaper';
import CheckinChild from './ops/CheckinChild';
import CheckoutChild from './ops/CheckoutChild';
import Sleep from './ops/Sleep';
import Meal from './ops/Meal';
import SoundAlert from './SoundAlert';
import OverdueTasksCalculator from './shared/OverdueTasksCalculator';
import Awakening from './ops/Awakening';

const App = () => {
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [user, setUser] = useState(null); // Zustand für den Benutzer
  const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);  
  const [loginErrorCode, setLoginErrorCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(null);
  const [attendingChildren, setAttendingChildren] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false); // wird im useeffect für das laden von employee und attendingchildren data gebraucht wenn isdeviceRegistered auf true gesetzt wird
  const [isSoundAlertOpen, setIsSoundAlertOpen] = useState(false);
  const [alarmStarted, setAlarmStarted] = useState(false);
  const [kitaOperationsTasks, setkitaOperationsTasks] = useState(null);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [latestActivity, setLatestActivity] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const currentDateRef = useRef(currentDate);
  const overdueTasksRef = useRef(overdueTasks);
  const alarmStartedRef = useRef(alarmStarted);
  const [operationSavedTrigger, setOperationSavedTrigger] = useState(false);
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false); // Neuer Zustand für das Modal

  //const [newDate, setNewDate] = useState(currentDate);

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Gibt das Datum im Format YYYY-MM-DD zurück
  };

    // useEffect zum Setzen des aktuellen Datums beim ersten Laden der Web-App
    useEffect(() => {
      const todayDate = getTodayDate();
      setCurrentDate(todayDate); // Setzen des heutigen Datums beim ersten Rendern
      //setNewDate(todayDate);
    }, []);

    // Setze currentDateRef.current, wenn currentDate sich ändert
    useEffect(() => {
      currentDateRef.current = currentDate;
    }, [currentDate]);

    // wird vom service-worker getriggert um den alarm zu starten und zu stoppen
    useEffect(() => {
      let audio = new Audio('/alarm.mp3');
    
      const handleServiceWorkerMessage = event => {
        if (event.data.type === 'playAudio') {
          audio.loop = true;
          audio.play().catch(err => console.error('Error playing audio:', err));
        } else if (event.data.type === 'stopAudio') {
          audio.pause();
          audio.currentTime = 0;  // Zurücksetzen des Audios auf den Anfang
        }
      };
    
      navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
        audio.pause();  // Stellen Sie sicher, dass das Audio gestoppt wird, wenn die Komponente unmounted wird
      };
    }, []);
    
    
  // Überprüfen der überfälligen Aufgaben und Aktualisieren des Sound-Alerts
  useEffect(() => {
    const soundInterval = setInterval(() => {
      const newTodayDate = getTodayDate();
      if (newTodayDate !== currentDateRef.current && currentDateRef.current) {
        console.log("Tagesabschluss: ", currentDateRef.current);
        setIsSoundAlertOpen(false);
        setAlarmStarted(false);
        setAttendingChildren([]);
        console.log("attending CHILDREN wurde gerade auf leer gestellt!");
        setOverdueTasks([]);
        setInitialDataLoaded(false);
        setCurrentDate(newTodayDate); // Aktualisieren des Datums, wenn es sich geändert hat, damit Showoperations in Home.js neu gerendert wird
        console.log("Neur Tag: ", newTodayDate);
        //updateOperationSavedTrigger(!operationSavedTrigger); //aktualisiere auch die showoperations timeline wenn ein nächster tag eintritt
      }
      if (
        overdueTasksRef.current && 
        overdueTasksRef.current.changeDiaper && 
        overdueTasksRef.current.noDiaperChange && 
        overdueTasksRef.current.noMeal && 
        (overdueTasksRef.current.changeDiaper.length > 0 || overdueTasksRef.current.noDiaperChange.length > 0 || overdueTasksRef.current.noMeal.length > 0) &&
        !alarmStartedRef.current && 
        attendingChildren
      ) {
        setIsSoundAlertOpen(true);
      } else {
        console.log("alarm nicht gestartet");
        console.log("overdueTasksRef.current: ", overdueTasksRef.current);
        console.log("overdueTasksRef.current.changeDiaper: ", overdueTasksRef.current.changeDiaper);
        console.log("overdueTasksRef.current.noDiaperChange: ", overdueTasksRef.current.noDiaperChange);
        console.log("overdueTasksRef.current.noMeal: ", overdueTasksRef.current.noMeal);
        console.log("overdueTasksRef.current.changeDiaper.length: ", overdueTasksRef.current.changeDiaper.length);
        console.log("overdueTasksRef.current.noDiaperChange.length: ", overdueTasksRef.current.noDiaperChange.length);
        console.log("overdueTasksRef.current.noMeal.length: ", overdueTasksRef.current.noMeal.length);
        console.log("!alarmStartedRef.current: ", !alarmStartedRef.current);
        console.log("attendingChildren: ", attendingChildren);
        //console.log("attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.length: ", attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.length);
        updateOperationSavedTrigger(!operationSavedTrigger);
        // Optional: Hier können Sie zusätzlichen Code hinzufügen, falls erforderlich
      }
      

    }, 300000);

    // Clean-up-Funktion für das Intervall
    return () => clearInterval(soundInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmStarted]);

  // Verwenden einer Ref, um den aktuellen Wert von overdueTasks zu speichern
  useEffect(() => {
    overdueTasksRef.current = overdueTasks;
  }, [overdueTasks]);
  

  const handleSoundAlertClose = () => {
    setIsSoundAlertOpen(false);
  };

  const handleAlarmStart = () => {
    setAlarmStarted(true); // Timer stoppen, wenn Alarm startet

  };
  
  const handleAlarmStop = () => {
    setAlarmStarted(false); // Timer starten, wenn Alarm gestoppt wird
    console.log("Alarm gestoppt");
  };
 
  const handleLogin = async (loginData) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, loginData.username, loginData.password);
      await loadTerminalData(userCredential.user);
      // Weiterführende Logik nach dem erfolgreichen Login
    } catch (error) {
      console.error('Login error:', error);      
      setLoginErrorCode(error.code);
    }
  };
  
  const handleLogout = async () => {
    // Zurücksetzen der Daten nach dem Logout
    setSelectedTerminal(null);
    setUser(null);
    setIsDeviceRegistered(false);
    setLoginErrorCode(null);
    setEmployeeData(null);
    setAttendingChildren([]);
    setInitialDataLoaded(false);
    setIsSoundAlertOpen(false);
    setAlarmStarted(false);
    setkitaOperationsTasks(null);
    setOverdueTasks([]);
    setLatestActivity([]);
    setCurrentDate('');
    setOperationSavedTrigger(null);
    setIsNewVersionAvailable(false);

    sessionStorage.clear();

    await auth.signOut();
  };

  const getNewestVersionId = async () => {
    const idToken = await auth.currentUser.getIdToken();
    const baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:5000' 
      : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
    const response = await fetch(`${baseUrl}/get-op-versionId`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch versionId from server');
    }
  
    const data = await response.json();
    //console.log('versionId wurde erfolgreich geladen: ', data.versionId);
    return data.versionId;
  };
  
  const loadAttendingChildrenData = useCallback(async (kitaId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const dateToUse = currentDate && currentDate !== '' ? currentDate : getTodayDate();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/calculate-attendance/${kitaId}?timestamp=${dateToUse}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
  
      if (!response.ok) {
        console.error('Server response:', response.status, await response.text());
        throw new Error('Failed to fetch attendance data');
      }
  
      const attendanceData = await response.json();
      console.log("set Attending Children data: ", attendanceData);
      setAttendingChildren(attendanceData);
    } catch (error) {
      console.error('Error in loadAttendanceData:', error);
    }
  }, [currentDate]);

  const loadTerminalData = useCallback(async (currentUser) => {
    try {
      const idToken = await currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/loadTerminal/${currentUser.uid}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        console.error('Server response:', response.status, await response.text());
        throw new Error('Failed to fetch terminal data from server');
      }

      const terminalData = await response.json();

      setSelectedTerminal(terminalData);
      console.log("terminal wurde geladen: ", terminalData);

      // Nach erfolgreichem Laden, prüfen Sie das deviceToken
      const deviceToken = localStorage.getItem('deviceToken');
      if (deviceToken) {
        
        //console.log("Es wurde EIN Token im Local Storage gefunden.")
        //console.log(deviceToken)
        const isTokenValid = await checkDeviceToken(currentUser.uid, terminalData.kitaData.kitaId, deviceToken);
        setIsDeviceRegistered(isTokenValid);
      } else {
        setIsDeviceRegistered(false);
        //console.log("Es wurde kein Token im Local Storage gefunden.")
      }
      setLoading(false);
    } catch (error) {
      console.error('Error in loadTerminalData:', error);
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await loadTerminalData(currentUser);
      } else {
        setLoading(false);  // Laden abgeschlossen, wenn kein Benutzer eingeloggt ist
      }
    });
  
    return () => unsubscribe();
  }, [loadTerminalData]);
  
  const loadEmployeeData = async (kitaId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:5000' 
        : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
      const response = await fetch(`${baseUrl}/employees/kita/${kitaId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        console.error('Server response:', response.status, await response.text());
        throw new Error('Failed to fetch employee data from server');
      }

      const employeeDataFetch = await response.json();
      console.log(employeeDataFetch);
      setEmployeeData(employeeDataFetch);
    } catch (error) {
      console.error('Error in loadEmployeeData:', error);
    }
  };

  // Funktion zum Löschen des Caches und Aktualisieren von localStorage
  const clearCacheAndReload = async () => {
    try {
      const versionId = await getNewestVersionId();
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          await caches.delete(cacheName);
        }
      }
      localStorage.setItem('deviceVersion', versionId);
      window.location.reload();
    } catch (error) {
      console.error('Error clearing cache and reloading:', error);
    }
  };

  // This useEffect is only called once when the webapp is reloaded
  useEffect(() => {
    const loadInitialData = async () => {
      if (isDeviceRegistered && selectedTerminal?.kitaData?.kitaId && !initialDataLoaded) {
        try {
          const versionId = await getNewestVersionId();
          const localDeviceVersion = localStorage.getItem('deviceVersion');

          if (!localDeviceVersion) {
            clearCacheAndReload();
          } else if (localDeviceVersion !== versionId) {
            setIsNewVersionAvailable(true);
            return; // Beende das Laden der Initialdaten, bis die Version bestätigt wurde
          }

          await Promise.all([
            loadEmployeeData(selectedTerminal.kitaData.kitaId),
            loadAttendingChildrenData(selectedTerminal.kitaData.kitaId)
          ]);
          updateOperationSavedTrigger(!operationSavedTrigger);
          setInitialDataLoaded(true); // Verhindert weitere Aufrufe
        } catch (error) {
          console.error('Error fetching versionId:', error);
        }
      }
    };

    if (user) {
      loadInitialData();
    }
    // Abhängigkeiten, die sich möglicherweise ändern und die Funktion erneut auslösen könnten, sollten überprüft werden
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeviceRegistered, user, selectedTerminal?.kitaData?.kitaId, initialDataLoaded, loadAttendingChildrenData]);
  

  // TerminalKontext functions
  const updateDeviceRegistrationStatus = (status) => {
    setIsDeviceRegistered(status);
  };

  const updateEmployeeData = (newData) => {
    setEmployeeData(newData);
  };

  const updateAttendingChildrenData = (newData) => {
    setAttendingChildren(newData);
  };

  const updateKitaOperationsTasks = (newData) => {
    //console.log(newData);
    //console.log("kitaOperationsTasks werden geupdatet");
    setkitaOperationsTasks(newData);
    //console.log("geupdatete KitaOperationsTasks");
    //console.log("kitaOperationsTasks länge auf app.js", newData.length);
  };

  const updateOverdueTasks = (newData) => {
    //console.log("update overdue Tasks", newData.length);
    //console.log("overdue Tasks", newData);
    setOverdueTasks(newData);
  };

  const updateOperationSavedTrigger = (newData) => {
    console.log("setOperationSavedTrigger gesetzt", newData);
    setOperationSavedTrigger(newData);
  };

  const updateLatestActivity = (newData) => {
    setLatestActivity(newData);
    //console.log("Latest Activity wird geupdatet in APP.js", newData);
  };

  // reload data when operationSavedTrigger changes, e.g. when logo in header is klicked
  useEffect(() => {
    console.log("selectedTerminal in app.js: ",selectedTerminal);
    if (selectedTerminal) {  // Prüfen Sie hier auf selectedTerminal
      console.log("operationSavedTrigger wurde geändert und useEffect in app.js aktiviert: ", operationSavedTrigger)
      loadAttendingChildrenData(selectedTerminal.kitaData.kitaId);
      loadEmployeeData(selectedTerminal.kitaData.kitaId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationSavedTrigger]);

  // für testzwecke
  //const handleSetCurrentDate = (date) => {
  //  console.log("Setting new date: ", date);
  //  setAttendingChildren([]);
  //  setInitialDataLoaded(false);
  //  setCurrentDate(date);
  //};
  

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <TerminalContext.Provider value={{
      selectedTerminal,
      employeeData,
      attendingChildren,
      kitaOperationsTasks,
      overdueTasks,
      operationSavedTrigger,
      latestActivity,
      updateDeviceRegistrationStatus,
      updateEmployeeData,
      updateAttendingChildrenData,
      updateKitaOperationsTasks,
      updateOverdueTasks,
      updateOperationSavedTrigger,
      updateLatestActivity
    }}>
      <div>
        {user && isDeviceRegistered && <MyNavbar onLogout={handleLogout} />}

        {/* Neues Datumsformular */}
        {/*<div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
          <input 
            type="date" 
            value={newDate} 
            onChange={(e) => setNewDate(e.target.value)} 
            style={{ marginRight: '10px' }}
          />
          <button onClick={() => handleSetCurrentDate(newDate)}>
            Speichern
          </button>
        </div>*/}

        <Routes>
          {user ? (
            isDeviceRegistered ? (
              <>
              <Route path="/" element={<Home currentDate={currentDate} />} />
              <Route path="/change-diaper" element={<ChangeDiaper />} />
              <Route path="/checkin-child" element={<CheckinChild />} />
              <Route path="/checkout-child" element={<CheckoutChild />} />
              <Route path="/meal" element={<Meal />} />
              <Route path="/sleep" element={<Sleep />} />
              <Route path="/awakening" element={<Awakening />} />
              </>
            ) : (
              <Route path="/" element={<RegisterDevice />} />
            )
          ) : (
            <Route path="/" element={<LoginForm onLogin={handleLogin} loginErrorCode={loginErrorCode} />} />
          )}
        </Routes>
        {user && isDeviceRegistered && <Footer />}
      </div>
      <OverdueTasksCalculator />
      {/* Rendern Sie das Modal außerhalb des Containers */}
      {isSoundAlertOpen && (
        <div className="modal-container">
          <SoundAlert 
            onClose={handleSoundAlertClose} 
            onAlarmStart={handleAlarmStart} 
            onAlarmStop={handleAlarmStop}
            overdueTasks={overdueTasks} // Übergeben des Zustands der überfälligen Aufgaben an den SoundAlert
          />
        </div>
      )}
      
      {/* Neues Modal für die neue Version */}
      <Modal show={isNewVersionAvailable} centered>
        <Modal.Header>
          <Modal.Title>Neue Version verfügbar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Eine neue Version dieser Anwendung ist verfügbar. Bitte laden Sie die Seite neu, um die neueste Version zu verwenden.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => clearCacheAndReload()}>
            Jetzt neue Version laden
          </Button>
        </Modal.Footer>
      </Modal>
    </TerminalContext.Provider>
  );
};

export default App;
