import React, { useState, useEffect, useContext, useCallback } from 'react';
import { auth } from '../firebase-config';
import TerminalContext from '../TerminalContext';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faStream } from '@fortawesome/free-solid-svg-icons';
import ACTION_DESCRIPTIONS from './config/actionTypes';
import getIconPath from './config/actionIcons'; // Importiere getIconPath
import useSharedFunctionsForActivities from './sharedFunctionsForActivities';
import '../styles/ShowOperations.css';

const ShowOperations = ({ action = '', givenDate = '', operationSaved, resetOperationSaved }) => {
  const [operations, setOperations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('timeline'); // Zustand für die Ansichtsmodus
  const [currentTime, setCurrentTime] = useState(new Date()); // Zustand für die aktuelle Zeit
  const { selectedTerminal, employeeData, attendingChildren, updateKitaOperationsTasks, operationSavedTrigger, kitaOperationsTasks  } = useContext(TerminalContext);  
  const [filteredOperations, setFilteredOperations] = useState([]);  
  const [selectedChildId, setSelectedChildId] = useState(null);
  const { calculateLatestActivity } = useSharedFunctionsForActivities();
  const [group, setGroup] = useState(null);

  // Aktualisiere die aktuelle Zeit jede Sekunde
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleResetOperationSaved = useCallback(() => {
    resetOperationSaved();
  }, [resetOperationSaved]);  

  useEffect(() => {
    const fetchOperations = async () => {
      if (!selectedTerminal || !selectedTerminal.kitaData.kitaId || !employeeData || !attendingChildren || !attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance || attendingChildren.length === 0) {
        setOperations([]);
        setFilteredOperations([]);
        return;
      }
      
      console.log("Fetch Operations wurde gestartet für folgendes Datum: ", givenDate);
      setLoading(true);

      const actionDefault = 'none';
      
      const givenDateFormatted = formatDateToDmy(new Date(givenDate));
      try {
        const token = await auth.currentUser.getIdToken();
        const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000' 
          : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';
        const url = `${baseUrl}/get-ops-action/${selectedTerminal.kitaData.kitaId}/${givenDateFormatted}/${actionDefault}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch operations');

        const data = await response.json();
        if(data.found) {
          //console.log("ShowoperatonsDataFetched: ", data);
          // Sortiere die Operationen nach dem Timestamp absteigend, bevor du sie verarbeitest
          const sortedTasks = data.tasks.sort((a, b) => b.executed_at._seconds - a.executed_at._seconds);

          const operationsData = sortedTasks.map(task => {
            const executedAt = new Date(task.executed_at._seconds * 1000);
            const employee = employeeData.find(emp => emp.uid === task.executed_by_UID);
            const child = attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(child => task.childRef._path.segments.includes(child.id));

            return {
              ...task,
              childName: `${child.firstname} ${child.lastname}`,
              executedByName: `${employee.firstname} ${employee.lastname}`,
              executedAt: executedAt.toLocaleDateString('de-DE') + ' ' + executedAt.toLocaleTimeString('de-DE'),
              action: ACTION_DESCRIPTIONS[task.action] || task.action,
              taskId: task.id,
              actionId: task.action,
              childId: task.childId,
            };
          });

          setOperations(operationsData);

          // Filter basierend auf der Aktion
          let filteredOps = [];
          if (action === 'checkoutChild') {
            filteredOps = operationsData.filter(op => op.actionId === 'checkoutChild');
          } else if (action === 'feedChild') {
            filteredOps = operationsData.filter(op => op.actionId === 'feedChild');
          } else if (action === 'checkinChild') {
            filteredOps = operationsData.filter(op => op.actionId === 'checkinChild');
          } else if (action === 'sleep') {
            filteredOps = operationsData.filter(op => op.actionId === 'sleep');
          } else if (action === 'changeDiaper') {
            filteredOps = operationsData.filter(op => op.actionId === 'changeDiaper');
          } else if (action === 'awakening') {
            filteredOps = operationsData.filter(op => op.actionId === 'awakening');
          } else {
            filteredOps = operationsData;
          }
        
          setFilteredOperations(filteredOps);
        } else {
          console.log("Keine Daten gefunden.");
          setOperations([]);
          setFilteredOperations([]);
        }
      } catch (error) {
        console.error('Error fetching operations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOperations();
    if (operationSaved) {
      handleResetOperationSaved();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationSaved, employeeData, operationSavedTrigger, givenDate]);

  useEffect(() => {
    let taskLength = 0;
    let operationsLength = 0;

    // Berechne die Länge von kitaOperationsTasks und addiere die Länge der timers Arrays
    if (kitaOperationsTasks !== null) {
      kitaOperationsTasks.forEach(task => {
        taskLength += 1; // Zähle die Aufgabe selbst
        if (task.timers && Array.isArray(task.timers)) {
          taskLength += task.timers.length; // Addiere die Länge des timers Arrays
        }
      });
      //console.log(kitaOperationsTasks);
    }

    // Berechne die Länge von operations und addiere die Länge der timers Arrays
    if (operations !== null) {
      operations.forEach(task => {
        operationsLength += 1; // Zähle die Aufgabe selbst
        if (task.timers && Array.isArray(task.timers)) {
          operationsLength += task.timers.length; // Addiere die Länge des timers Arrays
        }
      });
    }

    //console.log("Länge kitaOperationsTasks: ", taskLength);
    //console.log("Länge operations: ", operationsLength);
    //console.log("operations: ", operations);

    if (taskLength < operationsLength) {
      updateKitaOperationsTasks(operations);
      calculateLatestActivity(operations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations]);

  
  

  const formatDateToDmy = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1; // Monate beginnen bei 0
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Ansichtsmodus umschalten
  const toggleViewMode = () => {
  setViewMode(viewMode === 'table' ? 'timeline' : 'table');
  };

  
  // Filtere Kind das angeklickt wurde
  const handleOperationClick = (childId) => {
    if (selectedChildId === childId) {
        // Toggle back to show all operations
        if (action === 'none') {
          setFilteredOperations(operations);
        } else {
          setFilteredOperations(operations.filter(op => op.actionId === action));
        }
        setSelectedChildId(null);
    } else {
        // Filter operations to show only those for the clicked childId
        setFilteredOperations(filteredOperations.filter(op => op.childId === childId));
        setSelectedChildId(childId);
    }
  };


  // Finde die eindeutigen Kinder und sortiere sie alphabetisch, gefiltert nach der ausgewählten Gruppe
  const uniqueChildren = (filteredOperations.length === 0 || attendingChildren.length === 0) 
  ? [] 
  : Array.from(new Set(filteredOperations.map(op => op.childId)))
      .map(id => attendingChildren.combinedChildrenListForCalculatedAndCheckedAttendance.find(child => child.id === id))
      .filter(child => !group || child.groupRef._path.segments[3] === group)  // Filtere Kinder basierend auf der ausgewählten Gruppe
      .sort((a, b) => a.firstname.localeCompare(b.firstname));


  const handleGroupClick = (setter, value) => {
    setter(prevGroup => (prevGroup === value ? null : value));
  };
    

  return (
    <div>
      <div className="view-mode-toggle">
        <button onClick={toggleViewMode}>
          <FontAwesomeIcon icon={viewMode === 'table' ? faStream : faTable} />
        </button>
      </div>
  
      {viewMode === 'table' ? (
        // Tabellarische Ansicht
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Kind</th>
              <th>Task</th>
              <th>Von</th>
              <th>Um</th>
            </tr>
          </thead>
          <tbody>
            {filteredOperations.map((op, index) => (
              <tr key={index}>
                <td>{filteredOperations.length - index}</td>
                <td>{op.childName}</td>
                <td>{op.action}</td>
                <td>{op.executedByName}</td>
                <td>{op.executedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        // Timeline-Ansicht
        <div className="timeline-container">
        {/* Datum */}
          <div className="timeline-date">
            <h1>{currentTime.toLocaleDateString('de-DE', { day: 'numeric', month: 'long', year: 'numeric' })}</h1>
          </div>


        {/* Gruppenkacheln */}
        <div className="group-tiles-container">
          {selectedTerminal.kitaData.groups.map((groupItem) => (
            <div 
              key={groupItem.id} 
              className={`group-tile ${group === groupItem.id ? 'selected' : ''}`} 
              onClick={() => handleGroupClick(setGroup, groupItem.id)}
            >
              <div className="d-flex flex-column align-items-center p-2">
                <p className="m-0">{groupItem.name}</p>
              </div>
            </div>
          ))}
        </div>

          {/* Kinder Icons */}
          {uniqueChildren.length > 0 && (
            <div className="children-icons">
              {uniqueChildren.map(child => {
                return (
                  <div key={child.id} className="child-icon-container">
                    <img 
                      src={child.profilePic} 
                      alt={child.firstname} 
                      className={`child-icon ${selectedChildId === child.id ? 'highlight' : ''}`} 
                      onClick={() => handleOperationClick(child.id)}
                    />
                    <div className="child-name">{child.firstname}</div>
                  </div>
                );
              })}
            </div>
            )}
          {/* Aktuelle Uhrzeit */}
          <div className="timeline-time">
            {currentTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
          </div>
          {loading ? (
            <Spinner animation="border" className="spinner-with-margin" />
          ) : (
            <>
              {/* Die Mittellinie wird jetzt direkt von der timeline-time Klasse als Pseudo-Element erstellt */}
              {filteredOperations.length > 0 ? (
                filteredOperations.map((op, index) => (
                  <div key={index} className="operation-entry" onClick={() => handleOperationClick(op.childId)}>
                    {/* Uhrzeit der Operation */}
                    <div className="operation-date">
                      {new Date(op.executed_at._seconds * 1000).toLocaleTimeString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>

                    {/* Container für das Icon */}
                    <div className="operation-icon-container">
                      {/* Icon */}
                      <div className="operation-icon" style={{ backgroundImage: `url(${getIconPath(op.actionId, op.mealType)})` }}></div>
                    </div>
                    {/* Text der Operation */}
                    <div className={`operation-text ${selectedChildId === op.childId ? 'highlight' : ''}`}>
                      {(() => {                        switch (op.actionId) {
                        case 'changeDiaper':
                          return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.changeDiaper} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                        case 'checkinChild':
                          return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.checkinChild} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                        case 'checkoutChild':
                          return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.checkoutChild} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                        case 'feedChild':
                          if (op.mealType === 'Essen') {
                            const performance = op.eatPerformance === 'bad' ? 'weniger gut' : op.eatPerformance === 'good' ? 'gut' : 'sehr gut';
                            return (
                              <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.feedChildEat} von ${op.childName} durch ${op.executedByName}.<br/><strong>Menge: ${performance}.</strong>${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />
                            );
                          } else if (op.mealType === 'Trinken') {
                            return (
                              <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.feedChildDrink} von ${op.childName} durch ${op.executedByName}.<br/><strong>Menge: ${op.drinkAmount} ml.</strong>${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />
                            );
                          } else {
                            return <span dangerouslySetInnerHTML={{ __html: `Mahlzeit von ${op.childName} durch ${op.executedByName} durchgeführt.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                          }
                        case 'sleep':
                          return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.sleep} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                        case 'awakening':
                          return <span dangerouslySetInnerHTML={{ __html: `${ACTION_DESCRIPTIONS.awakening} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                        default:
                          return <span dangerouslySetInnerHTML={{ __html: `${op.action} von ${op.childName} durch ${op.executedByName}.${op.comment ? `<br/><span class="highlight-comment">Kommentar: ${op.comment}</span>` : ''}` }} />;
                      }
                      })()}
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-data-message">Keine Daten für den heutigen Tag gefunden.</p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowOperations;