import { auth } from '../firebase-config';

export const checkDeviceToken = async (uid, kitaId, deviceToken) => {
  try {
    const baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:5000' 
      : 'https://smartkita-backend-0fea91c0e62d.herokuapp.com';

    const response = await fetch(`${baseUrl}/check-device-token/${uid}/${kitaId}/${deviceToken}`, {
      headers: {
        Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
      }
    });

    if (!response.ok) {
      console.error('Server response:', response.status, await response.text());
      throw new Error('Failed to check device token');
    }

    const isValid = await response.json();
    return isValid;
  } catch (error) {
    console.error('Error checking device token:', error);
    return false;
  }
};
